import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import { setLoader } from '../../helper/table';
var AcknowledgeModal = function (_a) {
    var state = _a.state, dispatch = _a.dispatch, visible = _a.visible, onCancel = _a.onCancel, endpoint = _a.endpoint, acknowledgeNotesName = _a.acknowledgeNotesName, callbackFetch = _a.callbackFetch;
    var _b = useState(null), acknowledgeNotes = _b[0], setAcknowledgeNotes = _b[1];
    var onConfirm = function () {
        var _a;
        setLoader(state, dispatch, true);
        endpoint((_a = {},
            _a[acknowledgeNotesName] = acknowledgeNotes,
            _a)).then(function (resp) {
            if (resp.success) {
                toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
            }
            dispatch({
                type: 'setState',
                state: {
                    ids: [],
                    activeAcknowledgeItemId: null,
                },
            });
            setAcknowledgeNotes(null);
            callbackFetch();
        });
        onCancel();
    };
    return (_jsxs(Modal, { className: "modal-response modal-acknowledge", toggle: onCancel, isOpen: visible, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Mark acknowledge" }), _jsx(ModalBody, { children: _jsx(Form, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "description", children: "Description:" }), _jsx(Input, { id: "description", label: "Description", type: "textarea", required: true, onChange: function (ev) {
                                    return setAcknowledgeNotes(ev.currentTarget.value);
                                } })] }) }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { className: "response-knowledge", children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "success", onClick: onConfirm, children: "Acknowledge" })] }) })] }));
};
export default AcknowledgeModal;
