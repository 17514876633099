import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useIntersectionObserver } from '@uidotdev/usehooks';
import React, { memo, useEffect } from 'react';
import { Button, ButtonGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loader from '../../../components/Loader';
import DataTable from '../../../components/shared/Table/Table';
import { selectIsVisibleViewedAILogModal, useActionItemModalsActions, } from '../../../store/AIPreAi/useActionItemModalsStore';
import { selectIsCustomerDashboard, selectIsHideViewAi, useViewedLogActions, } from '../../../store/AIPreAi/useViewedLogStore';
import './ViewedAILog.scss';
var ViewedAILog = function (_a) {
    var _b, _c;
    var actionItemViewedLogData = _a.actionItemViewedLogData, isLoadingActionItemViewedLog = _a.isLoadingActionItemViewedLog, fetchNextPage = _a.fetchNextPage, hasNextPage = _a.hasNextPage, isFetchingNextPage = _a.isFetchingNextPage;
    var isVisibleViewedAILogModal = selectIsVisibleViewedAILogModal();
    var setIsVisibleViewedAILogModal = useActionItemModalsActions().setIsVisibleViewedAILogModal;
    var isCustomerDashboard = selectIsCustomerDashboard();
    var isHideViewAi = selectIsHideViewAi();
    var _d = useViewedLogActions(), setIsCustomerDashboard = _d.setIsCustomerDashboard, setIsHideViewAi = _d.setIsHideViewAi;
    var newActionItemViewedLogData = (_c = (_b = actionItemViewedLogData === null || actionItemViewedLogData === void 0 ? void 0 : actionItemViewedLogData.pages) === null || _b === void 0 ? void 0 : _b.flatMap(function (page) { return page.list; })) !== null && _c !== void 0 ? _c : [];
    var config = {
        columns: [
            {
                name: 'action',
                title: 'Action',
                component: function (_a) {
                    var action = _a.action;
                    return action.replaceAll('Create - action_list_reports', 'Create - action list report With metrics');
                },
            },
            {
                name: 'user_name',
                title: 'Username',
            },
            {
                name: 'created_at',
                title: 'Created at',
            },
            {
                name: 'notes',
                title: 'Notes',
            },
        ],
        sort: [
            {
                field: 'created_at',
                direction: 'desc',
            },
        ],
        pagination: {
            disabled: true,
        },
        hideTotalLabel: true,
        disabledSearch: true,
        data: newActionItemViewedLogData,
        loader: isLoadingActionItemViewedLog,
    };
    var _e = useIntersectionObserver({
        threshold: 0,
        root: null,
        rootMargin: '0px',
    }), ref = _e[0], entry = _e[1];
    useEffect(function () {
        if ((entry === null || entry === void 0 ? void 0 : entry.isIntersecting) && !isLoadingActionItemViewedLog && hasNextPage) {
            fetchNextPage();
        }
    }, [entry === null || entry === void 0 ? void 0 : entry.isIntersecting, hasNextPage]);
    return (_jsxs(Modal, { size: "xxl", className: "modal-viewed-ai-log modal-response", toggle: function () { return setIsVisibleViewedAILogModal(false); }, isOpen: isVisibleViewedAILogModal, children: [_jsx(ModalHeader, { toggle: function () { return setIsVisibleViewedAILogModal(false); }, children: "Action Item Log" }), _jsxs(ModalBody, { children: [_jsxs("div", { className: "d-flex mb-2", children: [_jsx("div", { className: "modal-viewed-ai-log-tab ".concat(isCustomerDashboard ? 'active' : ''), onClick: function () { return setIsCustomerDashboard(true); }, children: "Customer Dashboard" }), _jsx("div", { className: "modal-viewed-ai-log-tab ".concat(!isCustomerDashboard ? 'active' : ''), onClick: function () { return setIsCustomerDashboard(false); }, children: "Analytics Dashboard" }), _jsxs(Label, { check: true, className: "ms-4 d-flex align-items-center cursor-pointer", children: [_jsx(Input, { className: "mb-1", checked: isHideViewAi, type: "checkbox", onChange: function () { return setIsHideViewAi(!isHideViewAi); } }), _jsx("span", { className: "ms-1", children: "Hide View AI action" })] })] }), _jsx(DataTable, { config: config }), _jsx("div", { className: "w-100", ref: ref }), isFetchingNextPage ? (_jsx("div", { className: "d-flex justify-content-center", children: _jsx(Loader, { variant: "loader-sm" }) })) : (_jsx(_Fragment, {}))] }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: function () { return setIsVisibleViewedAILogModal(false); }, children: "Cancel" }) }) })] }));
};
export default memo(ViewedAILog);
