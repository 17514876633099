var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DataTable from '../../../shared/components/shared/Table/Table';
import { onPageChange, onPageSizeChange, onSearch, onSort } from '../../features/table/tableQuery';
import { useAnalystNotesDeleteMutation } from '../../services/notes/analystNotes/useAnalystNotesDeleteMutation';
import { useAnalystNotesListQuery, } from '../../services/notes/analystNotes/useAnalystNotesListQuery';
import { selectSortDataTable, useAnalystNotesDataTableActions, } from '../../store/analystNotes/useAnalystNotesDataTableStore';
import { INSTALLATION_POINT_GROUP, MULTI_GROUP, NODE_GROUP, useAnalystNotesModalsActions, } from '../../store/analystNotes/useAnalystNotesFormModalStore';
import { useConfirmModalActions } from '../../store/global/useConfirmModalStore';
var AnalystNotesDataTable = function (_a) {
    var options = _a.options, additionalNoteParam = _a.additionalNoteParam, isStaticTable = _a.isStaticTable;
    var _b = useAnalystNotesListQuery(options, isStaticTable), analystNotesListData = _b.data, isLoading = _b.isLoading, isFetching = _b.isFetching;
    var config = analystNotesListData.config;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var _c = useAnalystNotesModalsActions(), setIsVisibleModal = _c.setIsVisibleModal, setNote = _c.setNote, setSelectedGroup = _c.setSelectedGroup;
    var _d = useAnalystNotesDataTableActions(), setSearch = _d.setSearch, setPage = _d.setPage, setPageSize = _d.setPageSize, setSort = _d.setSort;
    var sort = selectSortDataTable();
    var _e = useAnalystNotesDeleteMutation(), deleteMutate = _e.mutate, deleteIsLoading = _e.isPending;
    var onDelete = function (id) {
        if (deleteIsLoading) {
            return;
        }
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure you want to remove this note?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                deleteMutate(id, {
                    onSuccess: function () {
                        toast.success('Successfully deleted', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    },
                });
            },
        });
    };
    return (_jsx(_Fragment, { children: _jsx(DataTable, { config: config, isLoading: isLoading || isFetching, onPageChange: function (page) { return onPageChange(page, setPage); }, onPageSizeChange: function (pageSize) { return onPageSizeChange(pageSize, setPageSize, setPage); }, onSort: function (column_name, ev) { return onSort(column_name, sort, setSort, ev); }, callbacks: {
                onDelete: function (id) { return onDelete(id); },
                onEdit: function (note) {
                    var _a, _b, _c;
                    setIsVisibleModal(true);
                    setSelectedGroup(note.installation_point_id ? INSTALLATION_POINT_GROUP : note.node_id ? NODE_GROUP : MULTI_GROUP);
                    setNote(__assign({ id: note.id, text: (_a = note.text) !== null && _a !== void 0 ? _a : '', title: note.title, tags: note.tags, attachments: note.attachments, facility: {
                            id: (_b = note.facility) === null || _b === void 0 ? void 0 : _b.facility_id,
                            name: (_c = note.facility) === null || _c === void 0 ? void 0 : _c.name,
                        }, equipments: note.equipments.map(function (el) {
                            return {
                                id: el.equipment_id,
                                name: el.name,
                            };
                        }), components: note.components.map(function (el) {
                            return {
                                name: el.component,
                            };
                        }), installationPointId: note.installation_point_id, node_id: note.node_id }, additionalNoteParam(note)));
                },
            }, onSearch: function (search) { return onSearch(search, setSearch, setPage); } }) }));
};
export default memo(AnalystNotesDataTable);
