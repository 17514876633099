import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import React, { useContext } from 'react';
import { Badge } from 'reactstrap';
import { ACTION_LIST, ACTION_LIST as ACTION_LIST_AMAZON } from '../../../../../../../amazon/constants';
import { ACTION_LIST as ACTION_LIST_REMASTERED, SYSTEM_TYPE_REMASTERED, } from '../../../../../../../constants';
import { SystemTypeContext } from '../../../../../../context/SystemTypeContext';
import LinkWithPrefix from '../../../../../LinkWithPrefix';
import TooltipBlockData from '../components/TooltipBlockData/TooltipBlockData';
import { getLabelData } from '../features';
var ActionItemLabel = function (_a) {
    var existInList = _a.existInList;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var ACTION_LIST = selectedSystemType === SYSTEM_TYPE_REMASTERED ? ACTION_LIST_REMASTERED : ACTION_LIST_AMAZON;
    var data = getLabelData(existInList, 'actionList');
    return (_jsx(_Fragment, { children: data ? (_jsx("div", { className: "tag-item", children: _jsxs(TooltipBlockData, { icon: _jsx(LinkWithPrefix, { to: "/action-item/".concat(data.value.id), target: "_blank", style: { textDecoration: 'none' }, children: _jsx(Badge, { color: "danger", children: "Action item" }) }), children: [_jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "Title:" }), data.value.title || '---'] }), _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "Urgency:" }), ACTION_LIST.URGENCY_NAMES[+data.value.urgency] ||
                                '---'] }), _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "State:" }), selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(ActionItemStateRemastered, { data: data })) : (_jsx(ActionItemStateAmazon, { data: data }))] }), _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "Created by:" }), data.value.created_by || '---'] }), _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "Time created:" }), "UTC: ", moment(data.value.time_created).format('MM/DD/YYYY, h:mm A') || '---'] }), _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "Updated at:" }), "UTC: ", moment(data.value.updated_at).format('MM/DD/YYYY, h:mm A') || '---'] })] }) })) : (_jsx(_Fragment, {})) }));
};
export default React.memo(ActionItemLabel);
export var ActionItemStateRemastered = function (_a) {
    var _b, _c, _d, _e;
    var data = _a.data;
    var isTroubleshootingHold = Number((_b = data === null || data === void 0 ? void 0 : data.value) === null || _b === void 0 ? void 0 : _b.is_troubleshooting_hold) || null;
    var isWaitesNew = (Number((_c = data === null || data === void 0 ? void 0 : data.value) === null || _c === void 0 ? void 0 : _c.analyst_comments_count) === 0 &&
        ((_d = data === null || data === void 0 ? void 0 : data.value) === null || _d === void 0 ? void 0 : _d.state) == ACTION_LIST_REMASTERED.STATE_WAITING) ||
        ((_e = data === null || data === void 0 ? void 0 : data.value) === null || _e === void 0 ? void 0 : _e.is_waites_new) ||
        null;
    return (_jsx(_Fragment, { children: isTroubleshootingHold
            ? ACTION_LIST_REMASTERED.STATUS_NAMES[ACTION_LIST_REMASTERED.STATE_TROUBLESHOOTING_HOLD]
            : isWaitesNew
                ? ACTION_LIST_REMASTERED.STATUS_NAMES[ACTION_LIST_REMASTERED.STATE_WW_NEW]
                : ACTION_LIST_REMASTERED.STATUS_NAMES[+data.value.state] || '---' }));
};
export var ActionItemStateAmazon = function (_a) {
    var data = _a.data;
    var isTroubleshootingHoldWaiting = !!data.value.is_troubleshooting_waiting;
    return (_jsx(_Fragment, { children: isTroubleshootingHoldWaiting ? (_jsx(_Fragment, { children: "Troubleshooting Hold - Question" })) : (_jsxs(_Fragment, { children: [ACTION_LIST.STATUS_NAMES[+data.value.state] ||
                    '---', ' '] })) }));
};
