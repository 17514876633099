import Api from '../../../api/api';
import { uploadUrl } from '../feature';
export var config = function (uploadImageUrl, customerId, storage) {
    return {
        buttons: [
            'file',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'eraser',
            'ul',
            'ol',
            'font',
            'lineHeight',
            'fontsize',
            'paragraph',
            'indent',
            'outdent',
            'table',
            'link',
            'image',
            'undo',
            'redo',
            'fullsize',
        ],
        askBeforePasteHTML: false,
        addNewLine: false,
        defaultActionOnPaste: 'insert_clear_html',
        cleanHTML: {
            allowTags: 'div, span, p, br, img, b, strong, i, em, a, ul, ol, li, blockquote, pre, u, s, table, tbody, thead, tr, th, td',
        },
        delete: {
            hotkeys: {
                delete: void 0,
            },
        },
        uploader: {
            url: uploadUrl(uploadImageUrl, customerId, storage),
            insertImageAsBase64URI: false,
            insertFileAsBase64URI: true,
            imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
            filesExtensions: ['.mp4', '.avi', '.mov', '.wmv'],
            headers: Api.getHeaders(true),
            withCredentials: false,
            method: 'POST',
            queryBuild: function (data) {
                var newData = new FormData();
                newData.append('file', data.getAll('files[0]')[0]);
                return newData;
            },
            buildData: function (data) {
                return data;
            },
            isSuccess: function (resp) {
                return !!resp.data.link;
            },
            process: function (resp) {
                var type = ['.jpg', '.png', '.jpeg', '.gif'].find(function (el) { return resp.name.includes(el); })
                    ? 'photo'
                    : ['.mp4', '.avi', '.mov', '.wmv'].find(function (el) { return resp.name.includes(el); })
                        ? 'video'
                        : null;
                return {
                    type: type,
                    files: [resp.data.link] || [],
                    name: (resp === null || resp === void 0 ? void 0 : resp.name) || type,
                };
            },
            defaultHandlerSuccess: function (data) {
                var _this = this;
                if (this.j) {
                    data.files.forEach(function (el) {
                        if (data.type === 'photo') {
                            var image = _this.j.createInside.element('img');
                            image.setAttribute('src', el);
                            _this.j.selection.insertNode(image);
                        }
                        if (data.type === 'video') {
                            var video = _this.j.createInside.element('a');
                            video.setAttribute('href', el);
                            video.innerHTML = data.name;
                            _this.j.selection.insertNode(video);
                        }
                    });
                }
                else {
                    data.files.forEach(function (el) {
                        if (data.type === 'photo') {
                            var image = _this.createInside.element('img');
                            image.setAttribute('src', el);
                            _this.selection.insertNode(image);
                        }
                        if (data.type === 'video') {
                            var video = _this.createInside.element('a');
                            video.setAttribute('href', el);
                            video.innerHTML = data.name;
                            _this.selection.insertNode(video);
                        }
                    });
                }
            },
        },
    };
};
