import Highcharts from 'highcharts';
import { get as _get } from 'lodash';
import { FREQUENCY_TYPES } from '../../../../../../constants';
var FrequencyConverter = /** @class */ (function () {
    function FrequencyConverter(units) {
        this.units = units;
    }
    FrequencyConverter.fromHz = function (value, speed) {
        var converter = new FrequencyConverter(FREQUENCY_TYPES.HZ);
        converter._setUnits(new Hz(value, speed));
        return converter;
    };
    FrequencyConverter.fromCpm = function (value, speed) {
        var converter = new FrequencyConverter(FREQUENCY_TYPES.HZ);
        converter._setUnits(new Cpm(value, speed));
        return converter;
    };
    FrequencyConverter.fromOrders = function (value, speed) {
        var converter = new FrequencyConverter(FREQUENCY_TYPES.HZ);
        return converter._setUnits(new Orders(value, speed));
    };
    FrequencyConverter.fromType = function (type, value, speed) {
        switch (type) {
            case FREQUENCY_TYPES.HZ:
                return FrequencyConverter.fromHz(value, speed);
            case FREQUENCY_TYPES.CPM:
                return FrequencyConverter.fromCpm(value, speed);
            case FREQUENCY_TYPES.ORDERS:
                return FrequencyConverter.fromOrders(value, speed);
        }
        throw new Error('Unknown frequency type');
    };
    FrequencyConverter.prototype._setUnits = function (units) {
        this.units = units;
        return this;
    };
    FrequencyConverter.prototype.toHz = function () {
        return this._setUnits(this.units.toHz());
    };
    FrequencyConverter.prototype.toCpm = function () {
        return this._setUnits(this.units.toCpm());
    };
    FrequencyConverter.prototype.toOrders = function () {
        return this._setUnits(this.units.toOrders());
    };
    FrequencyConverter.prototype.toType = function (type) {
        switch (type) {
            case FREQUENCY_TYPES.HZ:
                return this.toHz();
            case FREQUENCY_TYPES.CPM:
                return this.toCpm();
            case FREQUENCY_TYPES.ORDERS:
                return this.toOrders();
        }
        throw new Error('Unknown frequency type');
    };
    FrequencyConverter.prototype.numberFormat = function (precision, options) {
        return this.units.numberFormat(precision !== null && precision !== void 0 ? precision : 2, options);
    };
    FrequencyConverter.prototype.format = function (precision, options) {
        return this.units.format(precision || 2, options);
    };
    Object.defineProperty(FrequencyConverter.prototype, "value", {
        get: function () {
            return this.units.value;
        },
        enumerable: false,
        configurable: true
    });
    return FrequencyConverter;
}());
export { FrequencyConverter };
var Hz = /** @class */ (function () {
    function Hz(value, speed) {
        this.value = +value;
        this.speed = +speed;
    }
    Hz.prototype.toHz = function () {
        return this;
    };
    Hz.prototype.toCpm = function () {
        return new Cpm(this.value * 60, this.speed);
    };
    Hz.prototype.toOrders = function () {
        if (isNaN(this.speed) || this.speed === 0) {
            return 0;
        }
        return new Orders((this.value * 60) / this.speed, this.speed);
    };
    Hz.prototype.numberFormat = function (precision) {
        return Highcharts.numberFormat(this.value, precision, '.', '');
    };
    Hz.prototype.format = function (precision) {
        return "<b>".concat(this.numberFormat(precision), "</b> Hz");
    };
    return Hz;
}());
var Cpm = /** @class */ (function () {
    function Cpm(value, speed) {
        this.value = +value;
        this.speed = +speed;
    }
    Cpm.prototype.toHz = function () {
        return new Hz(this.value / 60, this.speed);
    };
    Cpm.prototype.toCpm = function () {
        return this;
    };
    Cpm.prototype.toOrders = function () {
        if (isNaN(this.speed) || this.speed === 0) {
            return 0;
        }
        return new Orders(this.value / this.speed, this.speed);
    };
    Cpm.prototype.numberFormat = function (precision) {
        return Highcharts.numberFormat(this.value, precision, '.', '');
    };
    Cpm.prototype.format = function (precision) {
        return "<b>".concat(this.numberFormat(precision), "</b> CPM");
    };
    return Cpm;
}());
var Orders = /** @class */ (function () {
    function Orders(value, speed) {
        this.value = +value;
        this.speed = +speed;
    }
    Orders.prototype.toHz = function () {
        if (isNaN(this.speed) || this.speed === 0) {
            return 0;
        }
        return new Hz((this.value * this.speed) / 60, this.speed);
    };
    Orders.prototype.toCpm = function () {
        if (isNaN(this.speed) || this.speed === 0) {
            return 0;
        }
        return new Cpm(this.value * this.speed, this.speed);
    };
    Orders.prototype.toOrders = function () {
        return this;
    };
    Orders.prototype.numberFormat = function (precision, options) {
        return Highcharts.numberFormat(this.value, precision || 2, '.', '') + (_get(options, 'withX') ? 'x' : '');
    };
    Orders.prototype.format = function (precision, options) {
        return "<b>".concat(this.numberFormat(precision, options), "</b>x Orders");
    };
    return Orders;
}());
