var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { DEFAULT_IMPACT_VUE_READING_CONFIG, IMPACT_VUE_FFT_TWF_CHART_LIST } from '../../../../constants';
var params = new URLSearchParams(window.location.search);
var initialState = {
    loader: true,
    data: {},
    readings: DEFAULT_IMPACT_VUE_READING_CONFIG,
    readingFftIds: {},
    isChangedCalendar: false,
    fftLastSelectedDate: null,
    fftSelectedDate: params.get('fft_date_impact_vue')
        ? new Date(params.get('fft_date_impact_vue') || '')
        : null,
    fftSelectedDateChartIdentifier: null,
    lastTimestamps: null,
    fftTimestampDates: {},
};
export var FftImpactVueChartsStore = create()(devtools(function (set, get) { return (__assign(__assign({}, initialState), { fetchFftReadings: function (endpoint) {
        set({ loader: true }, false, 'FftChartsStoreSetLoaderOnFetch');
        endpoint().then(function (resp) {
            if (resp && Object.values(resp).length > 0) {
                var readings_1 = {};
                var autoCorrelation_1 = {};
                var overall_1 = {};
                var pkpk_1 = {};
                var fftTimestamps_1 = {};
                Object.entries(resp).forEach(function (_a) {
                    var _b, _c, _d, _e, _f;
                    var key = _a[0], value = _a[1];
                    var newKey = Number(key) + 100;
                    if (IMPACT_VUE_FFT_TWF_CHART_LIST.includes(+newKey)) {
                        Object.assign(readings_1, (_b = {}, _b[+newKey] = value.data, _b));
                        Object.assign(autoCorrelation_1, (_c = {}, _c[+newKey] = value.autoCorrelation, _c));
                        Object.assign(overall_1, (_d = {}, _d[+newKey] = value.overall, _d));
                        Object.assign(pkpk_1, (_e = {}, _e[+newKey] = value.pkpk, _e));
                        Object.assign(fftTimestamps_1, (_f = {}, _f[+newKey] = value.data ? value.timestamp : '', _f));
                    }
                });
                set({
                    loader: false,
                    readings: readings_1,
                    data: __assign(__assign({}, get().data), { auto_correlation: autoCorrelation_1, overall: overall_1, pkpk: pkpk_1 }),
                });
            }
        });
    }, setStatesFftChartsStore: function (states) {
        set(states, false, 'FftChartsStoreSetStates');
    }, resetToDefault: function () {
        set(initialState);
    } })); }, { name: 'FftImpactVueChartsStore' }));
