import Api from '../../api';
export default {
    getDashboardWaitingActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/waiting', options);
    },
    getDashboardCustomerCreatedActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/customer-created', options);
    },
    getDashboardSubmittedReportActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/submitted-report', options);
    },
    getDashboardTroubleShootingWaitingActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/troubleshooting-waiting', options);
    },
    getDashboardDeclinedReportActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/with-declined-reports', options);
    },
    getActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list', options);
    },
    getActionListFilterInfo: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/filter-info', options);
    },
    getActionListViewedLog: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/viewed-log', options);
    },
    updateWwMissStatus: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/action-list-ww-misses/update-status', options);
    },
    uploadWwMissFiles: function (data, options) {
        return Api.post('amazon-analytic/action-list-ww-misses/upload-files', data, options);
    },
    deleteWwMissFile: function (body, options) {
        if (body === void 0) { body = {}; }
        return Api.post('amazon-analytic/action-list-ww-misses/delete-file', body, options);
    },
    getRejectedActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/ml-statistic/rejected-ai', options);
    },
    getRejectedActionListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/ml-statistic/rejected-ai-csv', options);
    },
    getMissedActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/ml-statistic/missed-ai', options);
    },
    removeMissedActionList: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.del("amazon-analytic/ml-statistic/missed-ai/".concat(id), options);
    },
    getMissedActionListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/ml-statistic/missed-ai-csv', options);
    },
    saveChart: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/action-list/save-charts', body, options);
    },
    suggestedClose: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/suggested-close', options);
    },
    replaceSensor: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/action-item/replace-sensor', options);
    },
    replaceMoteSensor: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/action-item/replace-mote-sensor', options);
    },
    replaceNodeBatteries: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/action-item/replace-node-batteries', options);
    },
    replaceMoteBattery: function (options) {
        if (options === void 0) { options = {}; }
        return Api.post('amazon-analytic/action-item/replace-mote-battery', options);
    },
    getActionListCsv: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/action-list/csv', options);
    },
};
