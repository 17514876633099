import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { FaCheckCircle, FaEdit, FaTimes, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, FormGroup, Input, Label, Table } from 'reactstrap';
import User from '../../../helper/user';
import { useConfirmModalActions } from '../../../store/global/useConfirmModalStore';
var CommentsBlock = function (_a) {
    var installationPointIdsInArr = _a.installationPointIdsInArr, comments = _a.comments, userComment = _a.userComment, readingType = _a.readingType, onChange = _a.onChange, afterDelete = _a.afterDelete, afterSaveComment = _a.afterSaveComment, isVisibleFormBlock = _a.isVisibleFormBlock, setIsVisibleFormBlock = _a.setIsVisibleFormBlock, isVisibleCommentsBlock = _a.isVisibleCommentsBlock, _b = _a.onChangePath, onChangePath = _b === void 0 ? 'comment' : _b, deleteAlertConditionComment = _a.deleteAlertConditionComment, saveAlertConditionsComment = _a.saveAlertConditionsComment;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var onSave = function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure you want to save comment?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                saveAlertConditionsComment({
                    installationPointIds: installationPointIdsInArr,
                    readingTypes: [readingType],
                    comment: userComment.comment,
                    comment_id: userComment.comment_id,
                }).then(function (resp) {
                    if (resp.success) {
                        toast.success(resp.message || 'Successfully saved comment', {
                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                        });
                        setIsVisibleFormBlock(false);
                        return afterSaveComment();
                    }
                    toast.error(resp.message || 'Server Error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
                });
            },
        });
    };
    var onDelete = function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure you want to delete comment?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                deleteAlertConditionComment(userComment.comment_id).then(function (resp) {
                    if (resp.success) {
                        toast.success(resp.message || 'Successfully Removed', {
                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                        });
                        setIsVisibleFormBlock(false);
                        return afterDelete(userComment.comment_id, readingType);
                    }
                    toast.error(resp.message || 'Server Error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
                });
            },
        });
    };
    var hasIdUserComment = comments.filter(function (el) { return el.id === userComment.comment_id; });
    return (_jsxs("div", { className: "mt-2", children: [isVisibleFormBlock || (!hasIdUserComment.length && isVisibleCommentsBlock) ? (_jsxs(FormGroup, { className: "d-flex flex-column", children: [_jsx(Label, { for: "textarea", children: "Comment:" }), _jsx(Input, { className: "fz-13 mb-2", id: "textarea", bsSize: "lg", type: "textarea", value: _get(userComment, 'comment', ''), onChange: function (ev) { return onChange(onChangePath, ev.currentTarget.value); } }), _jsxs("div", { className: "ms-auto", children: [_jsx(ButtonGroup, { children: _jsx(Button, { onClick: function () { return setIsVisibleFormBlock(false); }, outline: true, size: "sm", children: "Cancel" }) }), _jsx(ButtonGroup, { className: "ms-2", children: _jsx(Button, { onClick: function () { return onSave(); }, size: "sm", color: "primary", children: "Save" }) })] })] })) : (_jsx(_Fragment, {})), isVisibleCommentsBlock && comments.length > 0 && (_jsx(Table, { children: _jsx("tbody", { children: comments.map(function (comment) { return (_jsx("tr", { children: _jsxs("td", { className: "d-flex", children: [_jsxs("div", { className: "me-1", children: [comment.user_name, ":"] }), _jsx("span", { dangerouslySetInnerHTML: { __html: comment.comment } }), +comment.user_id === +User.getId() && (_jsx("div", { className: "ms-auto", children: _jsxs("div", { className: "d-flex justify-content-end", children: [_jsx(ButtonGroup, { className: "me-1", children: _jsx(Button, { size: "sm", color: "primary", title: "Update comment", onClick: function () { return setIsVisibleFormBlock(!isVisibleFormBlock); }, children: _jsx(FaEdit, {}) }) }), _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", color: "danger", title: "Delete comment", onClick: onDelete, children: _jsx(FaTrash, {}) }) })] }) }))] }) }, "alert_levels_comment_".concat(comment.id))); }) }) }))] }));
};
export default memo(CommentsBlock);
