import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { Input } from 'reactstrap';
import { ACTION_LIST } from '../../../../../../constants';
import { selectTypeDataTable, useFirmwareActions } from '../../../../../store/firmware/useFirmwareStore';
var TYPES = [
    ACTION_LIST.EQUIPMENT_TYPE_SENSOR,
    ACTION_LIST.EQUIPMENT_TYPE_NODE,
    ACTION_LIST.EQUIPMENT_TYPE_ROUTER,
    ACTION_LIST.EQUIPMENT_TYPE_GATEWAY,
    ACTION_LIST.EQUIPMENT_TYPE_MOTE,
];
var TYPES_NAMES = {
    sensor: 'Tethered Sensor',
    gateway: 'Gateway',
    node: 'Node',
    mote: 'Sensor Mote',
    router: 'Repeater',
};
var Filters = function () {
    var setType = useFirmwareActions().setType;
    var type = selectTypeDataTable();
    return (_jsx("div", { className: "mb-2", children: _jsxs(Input, { type: "select", name: "select", className: " h-auto w-250", onChange: function (ev) {
                return setType(ev.target.value);
            }, children: [_jsx("option", { selected: !type, value: "", children: "All" }), TYPES.map(function (type) { return (_jsx("option", { value: type, children: TYPES_NAMES[type] }, "types-filter-".concat(type))); })] }) }));
};
export default memo(Filters);
