import Api from '../../api';
export default {
    getFaultFrequencies: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/fault-frequency', options);
    },
    addFaultFrequency: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('all-analytics/fault-frequency', data);
    },
    updateFaultFrequency: function (faultFrequencyId, data) {
        if (data === void 0) { data = {}; }
        return Api.put("all-analytics/fault-frequency/".concat(faultFrequencyId), data);
    },
    deleteFaultFrequency: function (faultFrequencyId, data) {
        if (data === void 0) { data = {}; }
        return Api.del("all-analytics/fault-frequency/".concat(faultFrequencyId), data);
    },
};
