import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { invert as _invert, set as _set } from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { useEquipmentPhotoSaveMutation } from '../../services/equipmentPhotos/useEquipmentPhotoSaveMutation';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import '../../styles/EquipmentPhotos/index.scss';
import EquipmentPhotosViewer from './EquipmentPhotosViewer';
import EquipmentPointsList from './EquipmentPointsList';
var EquipmentPhotosContent = function (_a) {
    var _b;
    var saveTrigger = _a.saveTrigger, _c = _a.onClose, onClose = _c === void 0 ? function () { } : _c, loader = _a.loader, setLoader = _a.setLoader;
    var _d = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        equipmentPhotos: state.equipmentPhotos,
        setStates: state.setStates,
    }); }, shallow), installationPoint = _d.installationPoint, equipmentPhotos = _d.equipmentPhotos, setStates = _d.setStates;
    var equipmentPhotoSaveMutation = useEquipmentPhotoSaveMutation(installationPoint.equipment_id, installationPoint.id).mutate;
    var primaryPhoto = equipmentPhotos.find(function (p) { return p.is_primary_image === 1; });
    var _e = useState({}), flipTurns = _e[0], setFlipTurns = _e[1];
    var _f = useState((primaryPhoto === null || primaryPhoto === void 0 ? void 0 : primaryPhoto.id) || 0), mainImageId = _f[0], setMainImageId = _f[1];
    var _g = useState(equipmentPhotos.map(function (photo) { return ({ imgId: photo.id, installationPointId: photo.installation_point_id }); })), imageInstallationPoint = _g[0], setImageInstallationPoint = _g[1];
    var installationPointIds = _invert((((_b = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _b === void 0 ? void 0 : _b.installationPoints) || []).map(function (i) { return i.id; }));
    var initialPositions = {};
    equipmentPhotos.forEach(function (photo, index) {
        if (photo.positions.length > 0) {
            photo.positions.forEach(function (position) {
                if (position) {
                    _set(initialPositions, [
                        index,
                        position.installation_point_id === 0
                            ? 999
                            : installationPointIds[position.installation_point_id],
                    ], position);
                }
            });
        }
    });
    var _h = useState(initialPositions), positions = _h[0], setPositions = _h[1];
    var _j = useState(0), index = _j[0], setIndex = _j[1];
    var imageRef = useRef();
    var getImageIdByIndex = function (index) { var _a; return ((_a = equipmentPhotos[index]) === null || _a === void 0 ? void 0 : _a.id) || 0; };
    useEffect(function () {
        if (saveTrigger > 0) {
            setLoader(true);
            equipmentPhotoSaveMutation({
                flipTurns: flipTurns,
                mainImageId: mainImageId,
                imageInstallationPoint: imageInstallationPoint,
                positions: positions,
            }, {
                onSuccess: function (resp) {
                    if (resp.success) {
                        toast.success('Successfully updated', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                        setStates({ equipmentPhotos: resp.equipment_photos });
                        onClose();
                    }
                },
                onSettled: function () {
                    setLoader(false);
                },
            });
        }
    }, [saveTrigger]);
    return (_jsx(DndProvider, { backend: HTML5Backend, children: _jsxs(Row, { children: [_jsx(Col, { md: 8, children: _jsx(EquipmentPhotosViewer, { loader: loader, setLoader: setLoader, index: index, setIndex: setIndex, imageRef: imageRef, setPositions: setPositions, positions: positions, mainImageId: mainImageId, setMainImageId: setMainImageId, getImageIdByIndex: getImageIdByIndex, flipTurns: flipTurns, setFlipTurns: setFlipTurns }) }), _jsx(Col, { md: 4, children: equipmentPhotos.length > 0 ? (_jsx(EquipmentPointsList, { id: getImageIdByIndex(index), imageInstallationPoint: imageInstallationPoint, 
                        //@ts-ignore
                        setImageInstallationPoint: setImageInstallationPoint, setIndex: setIndex, index: index, positions: positions, setPositions: setPositions, imageRef: imageRef })) : (_jsx(_Fragment, {})) })] }) }));
};
export default memo(EquipmentPhotosContent);
