import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import DefaultTooltip from '../../../../shared/components/DefaultTooltip';
import { getIsBlockedActionOnAIPreAIPage } from '../../../../shared/helper/commonHelper';
var ExternalLink = function (_a) {
    var item = _a.item, index = _a.index, isFullSizeSidebar = _a.isFullSizeSidebar;
    var pathname = useLocation().pathname;
    var isBlockedToggleSidebarSize = getIsBlockedActionOnAIPreAIPage(pathname);
    return (_jsxs("a", { "data-tooltip-id": "sidebar_".concat(index), href: item.link, target: "_blank", rel: "noreferrer", children: [_jsx("div", { className: "menu__icon", children: item.icon() }), isFullSizeSidebar && !isBlockedToggleSidebarSize ? (_jsx(_Fragment, {})) : (_jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "right", id: "sidebar_".concat(index), children: item.title })), isFullSizeSidebar && !isBlockedToggleSidebarSize ? (_jsx("div", { className: "menu__title", children: item.getTitle ? item.getTitle() : item.title })) : (_jsx(_Fragment, {}))] }));
};
export default memo(ExternalLink);
