import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import { useReportEditorOptionUpdateMutation } from '../../services/reportEditor/useReportEditorOptionUpdateMutation';
var TITLE_MAPPING = {
    componentType: 'Component Type',
    subType: 'Sub Type',
    fault: 'Fault',
    recommendations: 'Recommendation',
};
var ReportEditorEntityEditModal = function (_a) {
    var reportEditorEntityEditState = _a.reportEditorEntityEditState, onCancel = _a.onCancel, _b = _a.callback, callback = _b === void 0 ? function () { } : _b;
    var _c = useState(''), name = _c[0], setName = _c[1];
    var reportEditorOptionUpdateMutation = useReportEditorOptionUpdateMutation().mutate;
    useEffect(function () {
        setName(reportEditorEntityEditState.name);
    }, [reportEditorEntityEditState.name]);
    var onSave = function () {
        reportEditorOptionUpdateMutation({ id: reportEditorEntityEditState.id, name: name, entityName: reportEditorEntityEditState.entityName }, {
            onSuccess: function (resp) {
                if (resp.success) {
                    toast.success('Successfully updated', { icon: _jsx(FaCheck, { style: { fill: '#198754' } }) });
                    if (callback) {
                        callback(reportEditorEntityEditState.entityName, name, reportEditorEntityEditState.id);
                    }
                    onCancel();
                }
            },
        });
    };
    return (_jsxs(Modal, { size: "lg", className: "modal-response", toggle: onCancel, isOpen: reportEditorEntityEditState.visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["Edit ", TITLE_MAPPING[reportEditorEntityEditState.entityName]] }), _jsx(ModalBody, { children: _jsx(Form, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "textarea", children: "Name:" }), _jsx(Input, { id: "name", label: "name", required: true, value: name, onChange: function (ev) { return setName(ev.target.value); } })] }) }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: onSave, children: "Save" })] }) })] }));
};
export default memo(ReportEditorEntityEditModal);
