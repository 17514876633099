import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { SYSTEM_TYPE_AMAZON } from '../../../constants';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import SelectInstallationPointOnEquipment from '../../modals/machineInfo/components/SelectInstallationPointOnEquipment';
import { selectSelectedInstallationPointIdDataTable, useAnalystNotesDataTableActions, } from '../../store/analystNotes/useAnalystNotesDataTableStore';
import AnalystNotesDataTable from '../analystNotes/AnalystNotesDataTable';
import { PMNotes } from './PMNotes';
import { SSDNotes } from './SSDNotes';
import { InstallAppNotes } from './installAppNotes';
export var Notes = function (_a) {
    var _b;
    var _c, _d;
    var options = _a.options, additionalNoteParam = _a.additionalNoteParam, installationPoint = _a.installationPoint, _e = _a.selectHidden, selectHidden = _e === void 0 ? false : _e;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var pointsOnEquipment = (_d = (_c = installationPoint.equipment) === null || _c === void 0 ? void 0 : _c.installationPoints) !== null && _d !== void 0 ? _d : [];
    var setSelectedInstallationPointId = useAnalystNotesDataTableActions().setSelectedInstallationPointId;
    var selectedInstallationPointId = selectSelectedInstallationPointIdDataTable() || installationPoint.id;
    var defaultTabTitle = "Analyst's Notes";
    var tabs = (_b = {},
        _b[defaultTabTitle] = {
            component: function () { return (_jsx(AnalystNotesDataTable, { additionalNoteParam: additionalNoteParam, options: options, isStaticTable: true })); },
            dependsOnInstallationPoint: true,
        },
        _b['SSD Notes'] = {
            component: function () { return _jsx(SSDNotes, { equipmentId: installationPoint.equipment_id }); },
            hidden: selectedSystemType === SYSTEM_TYPE_AMAZON,
        },
        _b['PM Note'] = {
            component: function () {
                return installationPoint.customer_id ? _jsx(PMNotes, { customerId: installationPoint.customer_id }) : null;
            },
        },
        _b['InstallApp Notes'] = {
            component: function () { return (_jsx(InstallAppNotes, { equipmentId: installationPoint.equipment_id, installationPointId: selectedInstallationPointId })); },
            dependsOnInstallationPoint: true,
        },
        _b);
    var tabsDependOnInstallationPoint = Object.entries(tabs)
        .filter(function (_a) {
        var _ = _a[0], dependsOnInstallationPoint = _a[1].dependsOnInstallationPoint;
        return dependsOnInstallationPoint;
    })
        .map(function (_a) {
        var title = _a[0];
        return title;
    });
    var _f = useState(defaultTabTitle), activeTab = _f[0], setActiveTab = _f[1];
    var ActiveTab = tabs[activeTab].component;
    var isActiveTabDependsOnInstallationPoint = tabsDependOnInstallationPoint.includes(activeTab);
    return (_jsxs(_Fragment, { children: [!selectHidden && (_jsx(Row, { className: "my-3", children: _jsx(Col, { md: 4, children: _jsx(SelectInstallationPointOnEquipment, { installationPoint: installationPoint, pointsOnEquipment: pointsOnEquipment, setSelectedInstallationPointId: setSelectedInstallationPointId, disabled: !isActiveTabDependsOnInstallationPoint }) }) })), _jsx(Nav, { tabs: true, className: "mb-3", children: Object.keys(tabs).map(function (title) { return (_jsx(NavItem, { children: _jsx(NavLink, { href: "#", active: activeTab === title, onClick: function () { return setActiveTab(title); }, children: title }) })); }) }), _jsx(ActiveTab, {})] }));
};
