import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import Loader from '../../components/Loader';
var EditingWatchListModal = function (_a) {
    var description = _a.description, visible = _a.visible, onCancel = _a.onCancel, callback = _a.callback, id = _a.id, endpoint = _a.endpoint;
    var _b = useState(description), desc = _b[0], setDesc = _b[1];
    var _c = useState(false), loader = _c[0], setLoader = _c[1];
    useEffect(function () {
        setDesc(description);
    }, [description]);
    if (!visible || !id) {
        return _jsx(_Fragment, {});
    }
    var onConfirm = function () {
        setLoader(true);
        endpoint(id, { description: desc }).then(function (resp) {
            setLoader(false);
            if (resp.success) {
                callback();
                onCancel();
                toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
            }
        });
    };
    return (_jsxs(Modal, { className: "modal-response", toggle: onCancel, isOpen: visible, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Editing description" }), _jsx(ModalBody, { children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-3", children: _jsx(Loader, { variant: "loader-md" }) })) : (_jsx(Form, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "datetime-local", children: "Description:" }), _jsx(Input, { style: {
                                    minHeight: '150px',
                                }, type: "textarea", value: desc, onChange: function (ev) { return setDesc(ev.target.value); } })] }) })) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { className: "response-ignore ", children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", disabled: loader, color: "primary", onClick: onConfirm, children: "Confirm" })] }) })] }));
};
export default memo(EditingWatchListModal);
