import Api from '../../api';
export default {
    getAlarmLevelsVerificationList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/auto-alert-conditions', options);
    },
    checkAutoAlarmLevelsList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/auto-alert-conditions/check', options);
    },
    confirmAutoAlertCondition: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('all-analytics/auto-alert-conditions/confirm', data);
    },
};
