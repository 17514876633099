var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { FFT_PLOTLINES_COLORS, getDataGroup } from '../../../../../../constants';
import { DEFAULT_CHARTS_CONFIG } from '../../../../../config/charts/defaultChartsConfig';
import { getUoms, isTwfAccelerationChart, isTwfChart } from '../../../../../helper/chart';
import { copyTextToClipboard } from '../../../../../helper/dom';
import { FftTooltipFormatter } from '../../components/shared/tooltip';
import { lockCursor, searchPeaks } from '../shared/cursor';
import { calculateSize } from '../shared/size';
export var setOptions = function (dispatch, state, data, fftTimestampDates, chartIdentifier, readings, fftAlerts, chartView, readingTypes, currentSpeed, chartRef, measure, personalSettingMeasure, axisLabelName) {
    var overall = data.overall, pkpk = data.pkpk, rpmFrom = data.rpmFrom;
    var options = state.options, isVisibleFullscreenChart = state.isVisibleFullscreenChart, isFFDetection = state.isFFDetection;
    var readingsYTwfAccelerationChart = [];
    if (isTwfAccelerationChart(chartIdentifier)) {
        readingsYTwfAccelerationChart = (_get(readings, chartIdentifier) || []).map(function (el) { return el[1]; });
    }
    var size = calculateSize(chartView);
    if (isVisibleFullscreenChart) {
        size = {
            height: (window.innerHeight - 200) * (isFFDetection ? 0.5 : 1),
            width: window.innerWidth - 80,
        };
    }
    var readingsData = _get(readings, chartIdentifier);
    var axisId = _get(readingTypes, [chartIdentifier, 'axisId']);
    var currentFftMeta = {};
    var currentTimestamp = _get(fftTimestampDates, chartIdentifier);
    if (currentTimestamp) {
        var day = currentTimestamp.split(' ')[0] || '';
        currentFftMeta = _get(data.fftTimestamps, [axisId, day], []).find(function (item) { return item.timestamp === currentTimestamp; });
    }
    dispatch({
        type: 'setState',
        state: {
            options: __assign(__assign({}, options), { title: {
                    text: axisLabelName,
                }, chart: __assign(__assign(__assign({}, options.chart), size), { events: {
                        click: function (ev) {
                            var _a;
                            var chart = ev.xAxis[0].axis.chart;
                            if (_get(ev.target, 'classList').contains('tooltip-harmonic-toggle-btn')) {
                                ev.preventDefault();
                                ev.stopPropagation();
                                chart.userOptions.hiddenHarmonicsInTooltip = !chart.userOptions.hiddenHarmonicsInTooltip;
                                return;
                            }
                            if (_get(ev.target, 'classList').contains('tooltip-peaks-toggle-btn')) {
                                ev.preventDefault();
                                ev.stopPropagation();
                                chart.userOptions.visiblePeaksInTooltip = !chart.userOptions.visiblePeaksInTooltip;
                                return;
                            }
                            if (isTwfChart(chartIdentifier) && chart.ctrlIsPressed) {
                                dispatch({
                                    type: 'setState',
                                    state: {
                                        selectedPoint: ((_a = chart === null || chart === void 0 ? void 0 : chart.hoverPoint) === null || _a === void 0 ? void 0 : _a.x) || null,
                                    },
                                });
                                return;
                            }
                            lockCursor(chart, chartRef, dispatch);
                        },
                    } }), yAxis: [
                    __assign(__assign({}, options.yAxis[0]), { title: {
                            text: "".concat(_get(readingTypes, [chartIdentifier, 'title'], ''), " (").concat(getUoms(chartIdentifier, measure, personalSettingMeasure), ")"),
                        } }),
                ], xAxis: [
                    __assign(__assign({}, options.xAxis[0]), { plotLines: [] }),
                ], pkpk: _get(pkpk, chartIdentifier), overall: _get(overall, chartIdentifier), installationPointId: _get(data, 'installationPoint.id'), peaks: searchPeaks(readingsData), lor: _get(currentFftMeta, 'lor'), fmax: _get(currentFftMeta, 'fmax'), series: [
                    {
                        id: "fft_".concat(chartIdentifier, "_").concat(_get(data, 'installationPoint.id')),
                        installationPointId: _get(data, 'installationPoint.id'),
                        data: readingsData,
                        color: '#7cb5ec',
                        name: _get(data, 'installationPoint.name'),
                        units: getUoms(chartIdentifier, measure, personalSettingMeasure),
                        chartType: chartIdentifier,
                        precision: readingTypes[chartIdentifier].precision,
                        visible: true,
                        showInLegend: true,
                        events: {
                            legendItemClick: function (e) {
                                e.preventDefault();
                                // @ts-ignore
                                var overlayNames = this.chart.series
                                    .filter(function (item) { return item.hasRendered; })
                                    .map(function (item) { return item.name; })
                                    .join(', ');
                                copyTextToClipboard(overlayNames, 'Name copied to clipboard');
                            },
                        },
                        enableMouseTracking: true,
                        dataGrouping: {
                            approximation: 'high',
                            forced: true,
                        },
                    },
                    {
                        id: "fft_auto_correlation_".concat(chartIdentifier, "_").concat(_get(data, 'installationPoint.id')),
                        data: _get(data, 'auto_correlation.' + chartIdentifier),
                        color: '#7cb5ec',
                        units: getUoms(chartIdentifier, measure, personalSettingMeasure),
                        name: _get(data, 'installationPoint.name'),
                        chartType: chartIdentifier,
                        precision: readingTypes[chartIdentifier].precision,
                        visible: false,
                        showInLegend: false,
                        enableMouseTracking: true,
                        dataGrouping: {
                            approximation: 'high',
                            forced: true,
                        },
                    },
                ], tooltip: __assign(__assign({}, options.tooltip), { formatter: function () {
                        // @ts-ignore
                        var points = this.points.filter(function (point) {
                            return point.series.options.id;
                        });
                        return ReactDOMServer.renderToStaticMarkup((_jsx(FftTooltipFormatter, { readingsYTwfAccelerationChart: readingsYTwfAccelerationChart, currentSpeed: currentSpeed, readingTypes: readingTypes, rpmFrom: _get(rpmFrom, chartIdentifier), points: points, 
                            // @ts-ignore
                            xValue: this.x })));
                    } }), plotOptions: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.plotOptions), { series: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.plotOptions.series), { point: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.plotOptions.series.point), { events: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.plotOptions.series.point.events), { click: function (ev) {
                                    var _a;
                                    var chart = ev.point.series.chart;
                                    if (isTwfChart(chartIdentifier) && chart.ctrlIsPressed) {
                                        dispatch({
                                            type: 'setState',
                                            state: {
                                                selectedPoint: ((_a = chart === null || chart === void 0 ? void 0 : chart.hoverPoint) === null || _a === void 0 ? void 0 : _a.x) || null,
                                            },
                                        });
                                        return;
                                    }
                                    lockCursor(chart, chartRef, dispatch);
                                } }) }) }) }) }),
            autoCorrelationIsVisible: false,
            isDataAvailable: true,
            mainSeriesLoaded: true,
            isVisibleFaultFrequencyIds: [],
        },
    });
};
export var getFftDates = function (fftTimestamps, readings, fftLastSelectedDate) {
    var dates = {};
    readings.map(function (chartIdentifier) {
        var _a;
        var dataGroup = getDataGroup(+chartIdentifier);
        if (_get(fftTimestamps, dataGroup)) {
            var fftDates = _get(fftTimestamps, dataGroup);
            var availableDates = Object.keys(fftDates);
            var lastDate = availableDates[availableDates.length - 1];
            var dateTimestamp = fftDates[lastDate][fftDates[lastDate].length - 1].timestamp;
            if (fftLastSelectedDate) {
                var newDateTimestampArr = (fftDates[moment(fftLastSelectedDate).format('YYYY-MM-DD')] || []).filter(function (el) { return el.timestamp.includes(moment(fftLastSelectedDate).format('YYYY-MM-DD HH:mm')); });
                if (newDateTimestampArr.length) {
                    dateTimestamp = newDateTimestampArr[0].timestamp;
                }
            }
            Object.assign(dates, __assign(__assign({}, dates), (_a = {}, _a[chartIdentifier] = dateTimestamp, _a)));
        }
    });
    return dates;
};
export var getPlotlineColor = function (index) {
    var colors = FFT_PLOTLINES_COLORS;
    if (index > colors.length) {
        return _get(colors, Math.random() * colors.length + 1);
    }
    return _get(colors, index, colors[0]);
};
