import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext, useState } from 'react';
import { Button } from 'reactstrap';
import StatusLabelAmazon from '../../../../../../amazon/components/AIPreAi/StatusLabel';
import { UrgencyLabel as UrgencyLabelAmazon } from '../../../../../../amazon/components/AIPreAi/UrgencyLabel';
import { ACTION_LIST, SYSTEM_TYPE_REMASTERED } from '../../../../../../constants';
import StatusLabelRemastered from '../../../../../../remastered/components/AIPreAi/StatusLabel';
import { UrgencyLabel } from '../../../../../../remastered/components/AIPreAi/UrgencyLabel';
import { DateTimeLabel } from '../../../../../../widgets/common';
import { SystemTypeContext } from '../../../../../context/SystemTypeContext';
import { onViewActionItem } from '../../../../../features/shared';
import { highlightRow } from '../../../../../helper/table';
import ShowMoreTable from './ShowMoreTable';
var ActionItemsTableRow = function (_a) {
    var actionItem = _a.actionItem, setCompletionReportModalState = _a.setCompletionReportModalState;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useState(false), showMore = _b[0], setShowMore = _b[1];
    return (_jsxs(_Fragment, { children: [_jsxs("tr", { children: [_jsx("td", { className: "card-row_title text-center", children: _jsx(DateTimeLabel, { id: "machine_info_ai_list_time_created_".concat(actionItem.id), dateTime: actionItem.time_created }) }), _jsx("td", { className: "card-row_title text-center", children: _jsx(DateTimeLabel, { id: "machine_info_ai_list_updated_at_".concat(actionItem.id), dateTime: actionItem.updated_at }) }), _jsx("td", { className: "card-row_title text-center", children: actionItem.created_by }), _jsx("td", { className: "card-row_title text-center", children: selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(UrgencyLabel, { urgency: +actionItem.urgency })) : (_jsx(UrgencyLabelAmazon, { urgency: +actionItem.urgency, replacementHistory: actionItem.replacementHistory, type: actionItem.type })) }), _jsx("td", { className: "card-row_title text-center", children: selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsx(StatusLabelRemastered, { status: +actionItem.state, commentsCount: 0, isWaitesNew: actionItem.is_waites_new, isTroubleshootingHold: +actionItem.is_troubleshooting_hold, isTroubleshootingWaiting: +actionItem.is_troubleshooting_waiting })) : (_jsx(StatusLabelAmazon, { status: +actionItem.state, commentsCount: +actionItem.comments_count, isTroubleshootingHoldWaiting: +actionItem.is_troubleshooting_hold_waiting })) }), _jsxs("td", { className: "card-row_title text-center", children: [_jsxs(Button, { color: "primary", className: "me-2 position-relative", size: "sm", onClick: function (ev) {
                                    onViewActionItem(actionItem.id, selectedSystemType);
                                    highlightRow(ev);
                                }, children: ["View", actionItem.type === ACTION_LIST.AI_TYPE_WW ? (_jsx("span", { className: "sub-badge sub-badge-letter", title: "Action required on Waites equipment attached to this monitored point", children: "W" })) : (_jsx(React.Fragment, { children: +actionItem.is_replace_equipment === 1 ? (_jsx("span", { className: "sub-badge sub-badge-letter", title: "This Action Item require replacing Waites equipment", children: "R" })) : (_jsx(_Fragment, {})) }))] }), _jsx(Button, { color: "secondary", className: "me-2 position-relative", size: "sm", onClick: function () { return setShowMore(function (showMore) { return !showMore; }); }, children: "Show more" })] })] }), showMore ? (_jsx("tr", { children: _jsx("td", { colSpan: 6, style: { padding: 0 }, children: _jsx(ShowMoreTable, { actionItem: actionItem, setCompletionReportModalState: setCompletionReportModalState }) }) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(ActionItemsTableRow);
