import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import DatePicker from 'react-datepicker';
import { FaCheck, FaEye, FaMinus, FaPlus, FaTrash } from 'react-icons/fa';
import { Button, ButtonGroup, Input } from 'reactstrap';
import { ALERTS_LIST } from '../../../../../constants';
import { DateInput } from '../../../../../widgets/common';
export var Filters = function (_a) {
    var callbacks = _a.callbacks, filtersData = _a.filtersData;
    var selectedLevel = filtersData.selectedLevel, batchSelectedLevelsType = filtersData.batchSelectedLevelsType, hasViewedAlert = filtersData.hasViewedAlert, showAcknowledge = filtersData.showAcknowledge, disabledBtns = filtersData.disabledBtns;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mb-2", children: [_jsx("div", { className: "response-select me-2 d-inline-block", children: _jsx(DatePicker, { selectsRange: true, onChange: callbacks.onChangeDateRange, dateFormat: "yyyy/MM/dd", isClearable: true, startDate: filtersData.dateRange[0], endDate: filtersData.dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) }) }), selectedLevel === ALERTS_LIST.CAUTION ? (_jsxs(Button, { size: "sm", className: "me-2", color: "warning", disabled: disabledBtns, onClick: function () { return callbacks.onChangeLevel(''); }, children: [_jsx(FaMinus, {}), _jsx("span", { children: " Hide Caution" })] })) : (_jsxs(Button, { size: "sm", className: "me-2", color: "warning", disabled: disabledBtns, onClick: function () { return callbacks.onChangeLevel(ALERTS_LIST.CAUTION); }, children: [_jsx(FaPlus, {}), _jsx("span", { children: " Add Caution" })] })), _jsxs(Button, { size: "sm", className: "me-2", color: !hasViewedAlert ? 'secondary' : 'primary', title: 'Jump to the last viewed alert', disabled: disabledBtns || !hasViewedAlert, onClick: function () { return callbacks.onLastViewed(); }, children: [_jsx(FaEye, {}), _jsx("span", { children: " Last viewed" })] }), _jsx(Button, { size: "sm", className: "me-2", disabled: disabledBtns, onClick: function () { return callbacks.onShowAcknowledge(); }, color: "info", children: _jsx(FaTrash, {}) }, "showAcknowledge")] }), showAcknowledge ? (_jsxs(React.Fragment, { children: [_jsx("div", { className: "float-start response-width me-2", children: _jsxs(Input, { type: "select", value: batchSelectedLevelsType || '', className: "select-sm", disabled: disabledBtns, onChange: function (ev) {
                                return callbacks.onBatchSelectChange(ev.target.value);
                            }, children: [_jsx("option", { value: "", children: "None" }), _jsx("option", { value: "allOnPage", children: "Set all alarm" }), _jsx("option", { value: ALERTS_LIST.CAUTION, children: "Set caution alarm" }), _jsx("option", { value: ALERTS_LIST.WARNING, children: "Set warning alarm" })] }) }), _jsx("div", { className: "float-start response-width me-2 ack", children: _jsx(ButtonGroup, { className: "", children: _jsxs(Button, { size: "sm", color: "success", className: "me-1", disabled: disabledBtns, onClick: function () { return callbacks.acknowledgeItem(''); }, children: [_jsx(FaCheck, {}), _jsx("span", { children: "Acknowledge Selected" })] }) }) })] })) : ('')] }));
};
