import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useReducer } from 'react';
import { Col, Row } from 'reactstrap';
import Endpoints from '../../../../../api/endpoints/endpoints';
import { METRIC_KEY } from '../../../../../constants';
import DataTable from '../../../../components/shared/Table/Table';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { reducer } from '../../../../helper/reducer';
import { InstallationPointStore } from '../../../../store/charts/chartsContent/InstallationPointStore';
import { selectMeasure } from '../../../../store/global/useGlobalStore';
import '../../MachineInfo.scss';
import { initialState } from './config/dataTableConfig';
import { fetch } from './features';
var AvgReadingsTab = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var _a = useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    var config = state.config;
    var measure = selectMeasure();
    useEffect(function () {
        fetch(state, dispatch, function () {
            return Endpoints[selectedSystemType].getAvgReadingMachineInfo(installationPoint.id, {
                query: {
                    selected_facility_metric: METRIC_KEY[measure],
                },
            });
        });
    }, []);
    return (_jsx(Row, { children: _jsx(Col, { children: _jsx("div", { className: "response-table mt-3", children: _jsx(DataTable, { config: config }) }) }) }));
};
export default AvgReadingsTab;
