import { each as _each } from 'lodash';
import { READING_TYPE_TEMPERATURE, TYPE_US } from '../../constants';
import { getMeasure } from '../../shared/helper/user';
var DEFAULT_CAUTION_FORMULA = 'avg + (stddev * 3) + 5';
var DEFAULT_ACC_CAUTION_FORMULA = '(avg + (stddev * 3)) * 1.2';
var DEFAULT_WARNING_FORMULA = 'caution_value * coefficient';
var DEFAULT_TEMP_WARNING_FORMULA = 'caution_value + 8.33';
var DEFAULT_TEMP_WARNING_FORMULA_US = 'caution_value + 15';
export var getDefaultCautionFormula = function (readingType) {
    return +readingType !== READING_TYPE_TEMPERATURE ? DEFAULT_ACC_CAUTION_FORMULA : DEFAULT_CAUTION_FORMULA;
};
export var getDefaultWarningFormula = function (readingType, measure, personalSettingMeasure) {
    if (+readingType === READING_TYPE_TEMPERATURE) {
        return getMeasure(readingType, measure, personalSettingMeasure) === TYPE_US
            ? DEFAULT_TEMP_WARNING_FORMULA_US
            : DEFAULT_TEMP_WARNING_FORMULA;
    }
    return DEFAULT_WARNING_FORMULA;
};
export var getCurrentFormula = function (history, levelName) {
    if (levelName === void 0) { levelName = 'caution'; }
    var formula = '';
    _each(history, function (item) {
        if (!formula && item.alertLevel.name.toLowerCase() === levelName.toLowerCase()) {
            formula = item.formula;
        }
    });
    return formula;
};
