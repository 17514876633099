var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { difference as _difference } from 'lodash';
import React, { memo } from 'react';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { AXIS_NAMES, AXIS_Z, READING_TYPES } from '../../../constants';
var AxisType = function (_a) {
    var selectedReadingTypeIds = _a.selectedReadingTypeIds, setSelectedReadingTypeIds = _a.setSelectedReadingTypeIds, chartIdentifiers = _a.chartIdentifiers, isSupportZAxis = _a.isSupportZAxis, _b = _a.showAll, showAll = _b === void 0 ? true : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c;
    return (_jsxs(Row, { className: "mt-2", children: [_jsx(Col, { md: 4, children: _jsx("span", { className: "fw-bold mt-2 d-block align-middle", children: "Axis Type:" }) }), _jsx(Col, { md: 8, children: _jsxs(Nav, { tabs: true, children: [showAll ? (_jsx(NavItem, { className: "custom-tab", children: _jsx(NavLink, { disabled: isDisabled, className: selectedReadingTypeIds.length === 3 ? 'active' : '', onClick: function () { return setSelectedReadingTypeIds(chartIdentifiers); }, children: "All" }) })) : (_jsx(_Fragment, {})), chartIdentifiers.map(function (chartIdentifier) {
                            var _a;
                            var axisShort = (_a = READING_TYPES[chartIdentifier]) === null || _a === void 0 ? void 0 : _a.axisShort;
                            if (!isSupportZAxis && axisShort === AXIS_NAMES[AXIS_Z]) {
                                return _jsx(_Fragment, {});
                            }
                            return (_jsx(NavItem, { className: "custom-tab", children: _jsx(NavLink, { disabled: isDisabled, className: selectedReadingTypeIds.includes(+chartIdentifier) ? 'active' : '', onClick: function () {
                                        return setSelectedReadingTypeIds(selectedReadingTypeIds.length === 1 && selectedReadingTypeIds.includes(+chartIdentifier)
                                            ? selectedReadingTypeIds
                                            : selectedReadingTypeIds.includes(+chartIdentifier)
                                                ? _difference(selectedReadingTypeIds, [+chartIdentifier])
                                                : __spreadArray(__spreadArray([], selectedReadingTypeIds, true), [+chartIdentifier], false));
                                    }, children: axisShort }) }, "alert-levels-axis-tab-".concat(chartIdentifier)));
                        })] }) })] }));
};
export default memo(AxisType);
