import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, size as _size } from 'lodash';
import React, { useCallback } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import InputRange from '../InputRange';
var FMaxSelect = function (_a) {
    var value = _a.value, sensorParameters = _a.sensorParameters, setFmax = _a.setFmax, isDisabled = _a.isDisabled, fmaxValues = _a.fmaxValues, _b = _a.axisId, axisId = _b === void 0 ? undefined : _b, loader = _a.loader, name = _a.name, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.isHFDvue, isHFDvue = _d === void 0 ? false : _d;
    if (loader && !value) {
        return (_jsx(React.Fragment, { children: _jsxs(FormGroup, { className: "mt-1", children: [_jsx(Label, { for: "textarea", children: "FMax:" }), _jsx(Input, { type: "select", name: "select", className: "select-sm", disabled: true })] }) }));
    }
    var maxFMax = _get(sensorParameters, 'maxFMax');
    var values = isHFDvue
        ? _get(sensorParameters, 'impactvue_fmax', []) || _get(sensorParameters, 'fmax', [])
        : _get(sensorParameters, 'fmax', []);
    var onChange = useCallback(function (value) {
        if (axisId) {
            return setFmax(name, value);
        }
        setFmax(value);
    }, [name, setFmax, axisId]);
    if (disabled) {
        return (_jsx(React.Fragment, { children: _jsxs(FormGroup, { className: "mt-1", children: [_jsx(Label, { for: "textarea", children: "FMax:" }), _jsx(Input, { className: "custom-mb", type: "number", name: name || 'f_max', value: value, disabled: true }), !!(+_get(sensorParameters, 'bandwidth') && +value > +_get(sensorParameters, 'bandwidth')) && (_jsx(React.Fragment, { children: _jsx("div", { className: "mt-1", children: !_size(fmaxValues) ? (_jsx("div", { className: "text-primary mb-2 mt-5 text-center", children: "Aliasing may occur if the Fmax is adjusted." })) : (_jsx("div", { className: "text-primary mb-2 mt-3 text-center", children: "The Fmax selected exceeds the flat response bandwidth." })) }) }))] }) }));
    }
    if (!_size(fmaxValues) && !isHFDvue) {
        return (_jsx(React.Fragment, { children: _jsxs(FormGroup, { className: "mt-1", children: [_jsx(Label, { for: "textarea", children: "FMax:" }), _jsx(Input, { type: "number", name: name || 'f_max', id: "title", value: value, step: 1, min: 10, max: maxFMax, onChange: function (e) {
                            if (+e.target.value <= maxFMax && +e.target.value >= 1) {
                                onChange(+e.target.value);
                            }
                        } }), _jsx("div", { className: "mt-1", children: _jsx(InputRange, { maxValue: +maxFMax, minValue: 10, value: +value, onChange: function (value) {
                                if (value <= +maxFMax && +value >= 1) {
                                    onChange(value);
                                }
                            } }) }), !!(+_get(sensorParameters, 'bandwidth') && +value > +_get(sensorParameters, 'bandwidth')) && (_jsx("div", { className: "text-primary mb-2 custom-mt text-center", children: "Aliasing may occur if the Fmax is adjusted." }))] }) }));
    }
    return (_jsx(React.Fragment, { children: _jsxs(FormGroup, { className: "mt-1", children: [_jsx(Label, { for: "textarea", children: "FMax:" }), _jsx(Input, { type: "select", name: name || 'f_max', className: "select-xl custom-mb", disabled: !_size(values) || isDisabled ? 'disabled' : '', value: value, onChange: function (ev) { return onChange(+ev.target.value); }, children: Object.values(values).map(function (value) { return (_jsx("option", { value: +value, children: value }, "fMax-".concat(value))); }) }), !!(+_get(sensorParameters, 'bandwidth') &&
                    +value > +_get(sensorParameters, 'bandwidth') &&
                    !isHFDvue) && (_jsx("div", { className: "text-primary mb-2 mt-3 text-center", children: "The Fmax selected exceeds the flat response bandwidth." }))] }) }));
};
export default React.memo(FMaxSelect);
