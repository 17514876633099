import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { MdOutlineAreaChart } from 'react-icons/md';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '../../../../../../store/charts/chartsContent/ChartFeaturesStore';
var HidePeaksButton = function (_a) {
    var width = _a.width, isDisabled = _a.isDisabled, state = _a.state, dispatch = _a.dispatch;
    var setStates = ChartFeaturesStore(function (state) { return ({
        setStates: state.setStates,
    }); }, shallow).setStates;
    return (_jsx("div", { className: "chart-btn", children: _jsx("div", { className: "chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), style: { width: width }, children: _jsx("span", { className: "btn btn-secondary btn-sm", onClick: function () {
                    setStates({
                        hidePeaksModal: {
                            visible: true,
                            state: state,
                            dispatch: dispatch,
                            chartIdentifier: state.options.chartIdentifier,
                        },
                    });
                }, children: _jsx(MdOutlineAreaChart, {}) }) }) }));
};
export default React.memo(HidePeaksButton);
