import moment from 'moment';
var isUserActivityLogged = function () {
    //@ts-ignore
    return +localStorage.getItem('isUserActivityLogged') === 1;
};
export var getLastActivityTime = function () {
    return localStorage.getItem('lastActivity') || moment().toDate();
};
export var setLogin = function () {
    localStorage.setItem('lastActivity', String(moment().toDate()));
    if (isUserActivityLogged()) {
        return true;
    }
    localStorage.setItem('isUserActivityLogged', '1');
};
export var setLogout = function () {
    // @ts-ignore
    var isUserActivityLogged = +localStorage.getItem('isUserActivityLogged') === 1;
    localStorage.setItem('isUserActivityLogged', '0');
    if (!isUserActivityLogged) {
        return;
    }
    localStorage.removeItem('lastActivity');
};
