var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import { Button, ButtonGroup, Input } from 'reactstrap';
import { DateInput } from '../../../widgets/common';
var params = new URLSearchParams(window.location.search);
var Filters = function (_a) {
    var state = _a.state, dispatch = _a.dispatch;
    var userNames = state.userNames, isCommentFieldActive = state.isCommentFieldActive, config = state.config, dateRange = state.dateRange, selectedUserName = state.selectedUserName;
    var onToggleField = function (isCommentFieldActive) {
        dispatch({
            type: 'setState',
            state: {
                isCommentFieldActive: isCommentFieldActive,
                config: __assign(__assign({}, config), { columns: config.columns.map(function (el) {
                        if (el.name === 'comment') {
                            return __assign(__assign({}, el), { visible: isCommentFieldActive });
                        }
                        if (el.name === 'description') {
                            return __assign(__assign({}, el), { visible: !isCommentFieldActive });
                        }
                        return el;
                    }) }),
            },
        });
    };
    var onChangeDateTime = function (dispatch, dateRange) {
        dispatch({
            type: 'setState',
            state: {
                dateRange: dateRange,
            },
        });
    };
    var onUserChange = function (user) {
        dispatch({
            type: 'setState',
            state: {
                selectedUserName: user,
            },
        });
    };
    return (_jsxs("div", { className: "d-flex mb-2", children: [_jsxs("div", { className: "d-flex", children: [_jsxs(Input, { type: "select", name: "select", className: "select-sm h-auto w-auto me-2", onChange: function (ev) { return onUserChange(ev.target.value); }, children: [_jsx("option", { selected: !selectedUserName, value: "", children: "All users" }), (userNames === null || userNames === void 0 ? void 0 : userNames.length) ? (userNames.map(function (user) { return (_jsx("option", { selected: params.get('selected_user_name') === user, value: user, children: user }, "users-on-demand-filter-".concat(user))); })) : (_jsx(_Fragment, {}))] }), _jsxs(ButtonGroup, { className: "me-2", children: [_jsx(Button, { size: "sm", onClick: function () { return onToggleField(false); }, color: !isCommentFieldActive ? 'primary' : 'secondary', children: "Description" }), _jsx(Button, { size: "sm", onClick: function () { return onToggleField(true); }, color: isCommentFieldActive ? 'primary' : 'secondary', children: "Comment" })] })] }), _jsx("div", { children: _jsx(DatePicker, { selectsRange: true, onChange: function (range) { return onChangeDateTime(dispatch, range); }, dateFormat: "MM/dd/yy", startDate: dateRange[0], endDate: dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) }) })] }));
};
export default memo(Filters);
