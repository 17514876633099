var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useConfirmModalActions } from '../../store/global/useConfirmModalStore';
export var useDeleteSeverityIncrease = function (_a) {
    var _b = _a.isPreAi, isPreAi = _b === void 0 ? false : _b, actionListId = _a.actionListId, _c = _a.confirmationText, confirmationText = _c === void 0 ? 'Are you sure you want to delete this item?' : _c, approveSeverityIncreaseMutate = _a.approveSeverityIncreaseMutate, declineSeverityIncreaseMutate = _a.declineSeverityIncreaseMutate, onSuccess = _a.onSuccess;
    if (!actionListId) {
        throw new Error('`actionListId` should be number');
    }
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var deleteSeverityIncrease = function (action) {
        return new Promise(function (resolve) {
            var mutate = action === 'approve' ? approveSeverityIncreaseMutate : declineSeverityIncreaseMutate;
            setConfirmData({
                isVisible: false,
            });
            mutate(isPreAi ? { pre_action_list_id: actionListId } : { action_list_id: actionListId }, {
                onSuccess: function () {
                    toast.success('Successfully remove', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                    onSuccess();
                    resolve(true);
                },
            });
        });
    };
    var confirmDeleteSeverityIncrease = function (_a) {
        var _b = _a === void 0 ? {} : _a, onSuccess = _b.onSuccess;
        var callback = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return __awaiter(void 0, void 0, void 0, function () {
                var ok;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, deleteSeverityIncrease.apply(void 0, args)];
                        case 1:
                            ok = _a.sent();
                            if (ok) {
                                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                            }
                            return [2 /*return*/];
                    }
                });
            });
        };
        setConfirmData({
            isVisible: true,
            textConfirmationModal: confirmationText,
            confirmText: 'Accept',
            additionalBtns: [
                {
                    text: 'Reject',
                    outline: false,
                    color: 'danger',
                    onAction: function () { return callback('decline'); },
                },
            ],
            onConfirm: function () { return callback('approve'); },
        });
    };
    return { confirmDeleteSeverityIncrease: confirmDeleteSeverityIncrease };
};
