var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useInfiniteQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { getHost } from '../../helper/customer';
import { selectIsVisibleViewedAILogModal } from '../../store/AIPreAi/useActionItemModalsStore';
import { selectIsCustomerDashboard, selectIsHideViewAi } from '../../store/AIPreAi/useViewedLogStore';
export var actionItemViewedLogKey = function (actionItemId, query) {
    return [
        'actionItemViewedLog',
        {
            actionItemId: +actionItemId,
            query: query,
        },
    ];
};
var LIMIT = 50;
var actionItemViewedLogFn = function (_a, coreLink, storage) {
    var queryKey = _a.queryKey, pageParam = _a.pageParam;
    var query = queryKey[1].query;
    var meta = {
        page: pageParam ? pageParam.length + 1 : 1,
        limit: LIMIT,
    };
    return Api.get("".concat(coreLink, "/action-list/viewed-log"), {
        query: __assign(__assign({}, query), { meta: meta }),
        host: getHost(storage),
    });
};
export var useActionItemViewedLogQuery = function (_a) {
    var actionItemId = _a.actionItemId, _b = _a.storage, storage = _b === void 0 ? null : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var isVisibleViewedAILogModal = selectIsVisibleViewedAILogModal();
    var isCustomerDashboard = selectIsCustomerDashboard();
    var isHideViewAi = selectIsHideViewAi();
    var query = {
        filters: {
            action_list_id: actionItemId,
        },
        is_hide_view_ai: +isHideViewAi,
        is_customer_dashboard: +isCustomerDashboard,
    };
    return useInfiniteQuery({
        queryKey: actionItemViewedLogKey(actionItemId, query),
        queryFn: function (data) { return actionItemViewedLogFn(data, getCoreUrl(selectedSystemType), storage); },
        getNextPageParam: function (lastPage, lastPageParam) {
            return lastPage.list.length === LIMIT ? lastPageParam : null;
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: !!actionItemId && isVisibleViewedAILogModal,
    });
};
