import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { onChangeFilter, onChangeOnlyIntralox, onChangePagination, onChangeSearch, onToggleCollapseExpand, } from '../../../features/suggestedLevels';
import PaginationComponent from '../../shared/Pagination/Pagination';
import './Filter.scss';
var Filters = function (_a) {
    var state = _a.state, dispatch = _a.dispatch, children = _a.children;
    var data = state.data, search = state.search, pagination = state.pagination, filters = state.filters, onlyIntralox = state.onlyIntralox;
    return (_jsx(Row, { className: "suggestedLevels-filters", children: _jsxs(Col, { children: [_jsxs("div", { className: "d-flex align-items-center flex-wrap", children: [_jsxs(Label, { className: "d-flex align-items-center mb-0", children: [_jsx("span", { className: "me-2", children: "Greater:" }), _jsx(Input, { onChange: function (ev) {
                                        return onChangeFilter(state, dispatch, 'greater', ev.target.value);
                                    }, type: "number", style: { width: '75px', height: '32px' }, value: filters.greater, placeholder: "%" })] }), _jsxs(Label, { className: "d-flex align-items-center mb-0 ms-3", children: [_jsx("span", { className: "me-2", children: "Less:" }), _jsx(Input, { onChange: function (ev) {
                                        return onChangeFilter(state, dispatch, 'less', ev.target.value);
                                    }, type: "number", style: { width: '75px', height: '32px' }, value: filters.less, placeholder: "%" })] }), children, _jsxs(Label, { check: true, className: "ms-5 mb-0", children: [_jsx(Input, { defaultChecked: onlyIntralox, onChange: function () { return onChangeOnlyIntralox(dispatch, onlyIntralox); }, type: "checkbox", value: onlyIntralox }), _jsx("span", { children: "Only Intralox" })] }), _jsxs(Label, { check: true, className: "ms-5 mb-0", children: [_jsx(Input, { defaultChecked: filters.isActions, onChange: function () { return onChangeFilter(state, dispatch, 'isActions', +!filters.isActions); }, type: "checkbox", value: filters.isActions }), _jsx("span", { children: "Has Actions" })] }), _jsxs(Label, { check: true, className: "ms-5 mb-0", children: [_jsx(Input, { defaultChecked: filters.notSet, onChange: function () { return onChangeFilter(state, dispatch, 'notSet', +!filters.notSet); }, type: "checkbox", value: filters.notSet }), _jsx("span", { children: "No set values" })] })] }), _jsxs("div", { className: "second-filter mt-3", style: { height: '32px' }, children: [_jsxs("div", { className: "me-auto d-flex align-items-center", children: [_jsx(Button, { className: "me-2", size: "sm", outline: true, onClick: function () { return onToggleCollapseExpand(state, dispatch, 'expand'); }, children: "Expand" }), _jsx(Button, { size: "sm", outline: true, onClick: function () { return onToggleCollapseExpand(state, dispatch, 'collapse'); }, children: "Collapse" })] }), _jsx(PaginationComponent, { config: { pagination: pagination, data: data }, onPageChange: function (page) { return onChangePagination(state, dispatch, 'page', page); }, onPageSizeChange: function (pageSize) { return onChangePagination(state, dispatch, 'pageSize', pageSize); } }), _jsx("div", { className: "search-block ms-2", children: _jsx(Input, { onChange: function (ev) {
                                    return onChangeSearch(state, dispatch, ev.target.value);
                                }, value: search, placeholder: "Search" }) })] })] }) }));
};
export default Filters;
