import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { FaRegLightbulb } from 'react-icons/fa';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { READING_TYPES } from '../../constants';
import { getUoms } from '../../shared/helper/chart';
import { useAnalystNotesCommentsByReadingTypeQuery, } from '../../shared/services/charts/useAnalystNotesCommnetsByReadingTypeQuery';
import { ChartsStore } from '../../shared/store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../shared/store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../shared/store/global/useGlobalStore';
import './AlertConditionsSettingsLabel.scss';
export var AlertConditionsSettingsLabel = function (_a) {
    var settings = _a.settings, styles = _a.styles, _b = _a.isCircle, isCircle = _b === void 0 ? false : _b;
    var chartIdentifier = +Object.keys(settings)[0];
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var data = useAnalystNotesCommentsByReadingTypeQuery({
        installationPointIds: [installationPoint.id],
        readingTypeIds: [chartIdentifier],
    }).data;
    var shouldDisplay = false;
    var chartAlertLevelsVisibleToggle = ChartsStore(function (state) { return state.chartAlertLevelsVisibleToggle; });
    Object.values(settings).map(function (setting) {
        if (setting.cautionSettings.is_ai ||
            setting.warningSettings.is_ai ||
            setting.cautionSettings.is_pre_ai ||
            setting.warningSettings.is_pre_ai) {
            shouldDisplay = true;
        }
    });
    if (chartAlertLevelsVisibleToggle || (!shouldDisplay && !(data !== null && data !== void 0 ? data : []).length)) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Badge, { style: styles, id: "alert-conditions-settings-label-".concat(chartIdentifier), className: "alert-conditions-settings-label ".concat(isCircle ? 'label-circle' : ''), color: "warning", children: _jsx(FaRegLightbulb, { size: 15 }) }), _jsxs(UncontrolledTooltip, { className: "alert-conditions-settings-label-tooltip", placement: "bottom", target: "alert-conditions-settings-label-".concat(chartIdentifier), children: [Object.values(settings).map(function (_a, index) {
                        var cautionSettings = _a.cautionSettings, warningSettings = _a.warningSettings;
                        return (_jsxs(React.Fragment, { children: [Object.values(settings).length > 1 && (_jsxs("div", { children: [_get(READING_TYPES, [chartIdentifier, 'title'], ''), ' ', _get(READING_TYPES, [Object.keys(settings)[index], 'axis'], '')] })), cautionSettings.is_ai || cautionSettings.is_pre_ai ? (_jsxs("div", { children: [cautionSettings.username, ": Caution ", cautionSettings.value, ' ', getUoms(Object.keys(settings)[index], measure, personalSettingMeasure), ' ', cautionSettings.trigger_time && cautionSettings.trigger_time > 0
                                            ? "".concat(cautionSettings.trigger_time, " minutes")
                                            : ''] })) : (_jsx(_Fragment, {})), warningSettings.is_ai || warningSettings.is_pre_ai ? (_jsxs("div", { children: [warningSettings.username, ": Warning ", warningSettings.value, ' ', getUoms(Object.keys(settings)[index], measure, personalSettingMeasure), ' ', warningSettings.trigger_time && warningSettings.trigger_time > 0
                                            ? "".concat(warningSettings.trigger_time, " minutes")
                                            : ''] })) : (_jsx(_Fragment, {}))] }, "alert-conditions-settings-label-".concat(chartIdentifier, "-").concat(index)));
                    }), data.map(function (comment) { return (_jsxs("div", { children: ["Has analyst notes by ", comment.user_name] }, comment.id)); })] })] }));
};
export default memo(AlertConditionsSettingsLabel);
