import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { Button, Input, UncontrolledTooltip } from 'reactstrap';
import { STATUS_PROCESSED, STATUS_WATCHING } from '../../../../constants';
import Loader from '../../Loader';
export var ActionBar = function (_a) {
    var item = _a.item, callbacks = _a.callbacks, filtersData = _a.filtersData;
    if (!filtersData) {
        return _jsx(_Fragment, {});
    }
    var disabledBtns = filtersData.disabledBtns;
    return (_jsx(React.Fragment, { children: _jsxs("div", { className: "action-bar", children: [_jsx("div", { className: "alert-check-input", children: item.isShowCheckbox ? (_jsx(Input, { type: "checkbox", checked: item.isSelected, disabled: disabledBtns, onChange: function () { return callbacks.onToggleIsSelectedAlert(item); } })) : (_jsx(_Fragment, {})) }), _jsx(AlertProcessing, { item: item }), _jsx(AlertToggleBtnOrLine, { item: item, callbacks: callbacks, disabledBtns: disabledBtns })] }) }));
};
var AlertProcessing = function (_a) {
    var item = _a.item;
    var alertProcessingComponent;
    var random_watching;
    var random_watched;
    switch (Number(item.analyst_status) || 0) {
        case STATUS_WATCHING:
            random_watching = Math.random().toString().slice(2);
            alertProcessingComponent = (_jsxs(_Fragment, { children: [_jsx("i", { id: "_".concat(item.id, "_working_").concat(random_watching), className: "fa fa-exclamation-circle text-danger fz-18" }), _jsx(UncontrolledTooltip, { target: "_".concat(item.id, "_working_").concat(random_watching), children: item.analyst_username + ' analyst is also working on this point' })] }));
            break;
        case STATUS_PROCESSED:
            random_watched = Math.random().toString().slice(2);
            alertProcessingComponent = (_jsxs(_Fragment, { children: [_jsx("i", { id: "_".concat(item.id, "_watched_").concat(random_watched), className: "fa fa-exclamation-circle text-primary fz-18" }), _jsx(UncontrolledTooltip, { target: "_".concat(item.id, "_watched_").concat(random_watched), children: item.analyst_username + ' watched ' + moment(item.analyst_updated_at).utc().fromNow() })] }));
            break;
        default:
            alertProcessingComponent = _jsx("span", { className: "hiddenBlock" });
    }
    return _jsx("span", { children: alertProcessingComponent });
};
var AlertToggleBtnOrLine = function (_a) {
    var item = _a.item, callbacks = _a.callbacks, disabledBtns = _a.disabledBtns;
    if (item.isFirstLevel || (item.children && item.children.length >= 2)) {
        return (_jsx(Button, { color: item.isOpenTree ? 'success' : 'primary', disabled: disabledBtns, className: "".concat(!item.isFirstLevel && item.children && item.children.length > 1
                ? 'second-level-btn'
                : 'first-level-btn', " ").concat(item.isOpenTree ? 'open' : ''), onClick: function () {
                if (item.isLoaderActive) {
                    return;
                }
                if (item.isFirstLevel && !item.children.length) {
                    callbacks.onRequestChildren(item);
                }
                else {
                    callbacks.onToggleTree(item, !item.isOpenTree);
                }
            }, size: "sm", children: item.isLoaderActive ? _jsx(Loader, { variant: "loader-exs" }) : item.isOpenTree ? _jsx(FaMinus, {}) : _jsx(FaPlus, {}) }));
    }
    if (item.children && item.children.length === 1) {
        return _jsx("div", { className: "line" });
    }
    return _jsx("div", { className: "directed-line ".concat(item.isLastChildren ? 'last' : '') });
};
