var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _this = this;
import { get as _get } from 'lodash';
import { DEFAULT_CHARTS_CONFIG } from '../../../../config/charts/defaultChartsConfig';
import { getUoms, reRenderCursorFft } from '../../../../helper/chart';
import { fftTicksPositioner, formatXAxisFFT } from '../components/fftTwf/FftAxisFormatter';
import { fftPositioner, prepareToMoveFftTwf } from '../components/shared/tooltip';
import { cursorMouseOverEventHandler } from '../features/shared/cursor';
import { calculateSize } from '../features/shared/size';
export var config = function (chartIdentifier, chartCursorType, installationPoint, readingTypes, currentSpeed, chartView, measure, personalSettingMeasure) {
    return {
        options: __assign(__assign({}, DEFAULT_CHARTS_CONFIG), { chart: {
                zoomType: 'x',
                panning: true,
                panKey: 'shift',
                resetZoomButton: {
                    position: {
                        align: 'left',
                        verticalAlign: 'top',
                        x: -4,
                        y: -6,
                    },
                },
                marginTop: 80,
                marginBottom: 60,
                height: calculateSize(chartView).height,
                width: null,
            }, title: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.title), { text: _get(readingTypes, [chartIdentifier, 'axis'], '') }), yAxis: [
                __assign(__assign({}, DEFAULT_CHARTS_CONFIG.yAxis[0]), { title: {
                        text: "".concat(_get(readingTypes, [chartIdentifier, 'title'], ''), " (").concat(getUoms(chartIdentifier, measure, personalSettingMeasure), ")"),
                    } }),
            ], xAxis: [
                __assign(__assign({}, DEFAULT_CHARTS_CONFIG.xAxis[0]), { type: '', crosshair: true, ordinal: false, plotLines: [], title: {
                        align: 'middle',
                        text: '',
                        useHTML: true,
                    }, labels: {
                        formatter: function () {
                            return formatXAxisFFT(this.chart, this.value, 'Hz', currentSpeed);
                        },
                    }, tickPositioner: function () {
                        return fftTicksPositioner(this.chart, this.max, 'Hz', currentSpeed);
                    }, events: {
                        afterSetExtremes: function (ev) {
                            var chart = _get(ev, ['target', 'chart']);
                            if (!chart) {
                                return true;
                            }
                            if (chart.resetZoomButton) {
                                chart.resetZoomButton.element.style.display = 'none';
                            }
                            reRenderCursorFft(chart, chart.userOptions.customCursorPointsCount, currentSpeed);
                        },
                    } }),
            ], chartIdentifier: chartIdentifier, chartCursorType: chartCursorType, customCursorPointsCount: 10, units: 'Hz', installationPointSpeed: _get(installationPoint, 'speed'), installationPointId: _get(installationPoint, 'id'), equipmentCurrentSpeed: _get(installationPoint, 'equipment.current_speed'), type: 'line', plotOptions: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.plotOptions), { series: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.plotOptions.series), { connectNulls: true, lineWidth: 1, states: {
                        hover: {
                            lineWidth: 1,
                        },
                        inactive: {
                            opacity: 1,
                        },
                    }, point: {
                        events: {
                            mouseOver: function (e) {
                                var chart = e.target.series.chart;
                                cursorMouseOverEventHandler(chart, currentSpeed, chart.userOptions.customCursorPointsCount, e.target.series.chart.altIsPressed);
                                prepareToMoveFftTwf(this);
                            },
                        },
                    } }) }), series: [], tooltip: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.tooltip), { positioner: function (labelWidth) {
                    // @ts-ignore
                    return fftPositioner(labelWidth, this.chart);
                }, formatter: function () { }, shape: 'square', shared: true, backgroundColor: 'rgba(255, 255, 255, .85)' }), exporting: __assign(__assign({}, DEFAULT_CHARTS_CONFIG.exporting), { sourceWidth: 1500, scale: 1, chartOptions: {
                    chart: {
                        // @ts-ignore
                        height: _this ? _this.chartHeight : 0,
                    },
                } }), annotations: [] }),
        mainSeriesLoaded: false,
        isDataAvailable: false,
        autoCorrelationIsVisible: false,
        isVisibleFullscreenChart: false,
        isChartSelectionChecked: false,
        isChartPainting: false,
        isLockedCursor: false,
        customCursorPointsCount: 10,
        fftFullscreenUserOptions: {},
        selectedPoint: null,
        units: 'Hz',
    };
};
