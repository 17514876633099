import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext, useEffect } from 'react';
import Loader from '../../../../components/Loader';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { stripHtml } from '../../../../helper/dom';
import { setActiveArticle, setViewedArticle } from '../../features';
var WhatsNewArticleList = function (_a) {
    var state = _a.state, dispatch = _a.dispatch;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var activeRelease = state.activeRelease, activeArticles = state.activeArticles, activeArticleId = state.activeArticleId, loaderArticle = state.loaderArticle;
    useEffect(function () {
        if (activeArticleId) {
            setViewedArticle(state, dispatch, selectedSystemType);
        }
    }, [activeArticleId]);
    return (_jsx(_Fragment, { children: activeRelease ? (_jsx("div", { className: "articleList-wrap", children: _jsxs("div", { className: "articleList", children: [_jsx("h4", { children: "Articles" }), _jsx("div", { className: "block" }), loaderArticle ? (_jsx("div", { className: "d-flex justify-content-center align-items-center h-100", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, { children: activeArticles.length ? (activeArticles.map(function (item) { return (_jsx("a", { href: '#' + stripHtml(item.title).trim().replaceAll(' ', '_'), onClick: function () { return setActiveArticle(dispatch, item.id); }, className: "articleList-item ".concat(!+item.is_viewed ? 'notReadPoint' : '', " ").concat(activeArticleId == +item.id ? 'activeView' : ''), dangerouslySetInnerHTML: { __html: item.title } }, item.id)); })) : (_jsx(_Fragment, {})) }))] }) })) : (_jsx(_Fragment, {})) }));
};
export default memo(WhatsNewArticleList);
