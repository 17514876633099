import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import { FaTimes } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { WATCH_LIST } from '../../../constants';
import { DateInput } from '../../../widgets/common';
var Filters = function (_a) {
    var onChangeState = _a.onChangeState, isFilterClick = _a.isFilterClick, children = _a.children, onChangeDateRange = _a.onChangeDateRange, dateRange = _a.dateRange;
    if (!isFilterClick) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: "mb-2", children: [_jsx("div", { className: "response-select me-2 d-inline-block", children: _jsx(DatePicker, { selectsRange: true, onChange: onChangeDateRange, dateFormat: "yyyy/MM/dd", isClearable: true, startDate: dateRange[0], endDate: dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) }) }), children || _jsx(_Fragment, {}), _jsx(Button, { size: "sm", className: "me-2", color: WATCH_LIST.STATE_FOLLOW_UP_COLOR, onClick: function () { return onChangeState(WATCH_LIST.IS_AI_EXIST); }, children: "Follow UP" }), _jsx(Button, { size: "sm", className: "me-2", color: WATCH_LIST.STATE_POTENTIAL_PROBLEM_COLOR, onClick: function () { return onChangeState(WATCH_LIST.AI_IS_NOT_EXISTING); }, children: "Potential problems" }), _jsx(Button, { size: "sm", color: "danger", onClick: function () { return onChangeState(); }, children: _jsx(FaTimes, {}) })] }));
};
export default memo(Filters);
