import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Button, ButtonGroup, Label } from 'reactstrap';
import { useReportEditorOptionsQuery, } from '../../../services/reportEditor/useReportEditorOptionsQuery';
import { useActionItemModalsActions } from '../../../store/AIPreAi/useActionItemModalsStore';
import '../../../styles/AIPreAi/description.scss';
import SelectedRecommendations from './SelectedRecommendations';
var ApproveReassignRecommendations = function (_a) {
    var recommendations = _a.recommendations, setIsRecommendationsApproved = _a.setIsRecommendationsApproved, setReassignedRecommendations = _a.setReassignedRecommendations, setInternalComment = _a.setInternalComment, isRecommendationsApproved = _a.isRecommendationsApproved;
    var _b = useState([]), modalRecommendations = _b[0], setModalRecommendations = _b[1];
    var setRecommendationsReassignModalState = useActionItemModalsActions().setRecommendationsReassignModalState;
    var reportEditorData = useReportEditorOptionsQuery().data;
    useEffect(function () {
        setModalRecommendations(recommendations);
    }, [recommendations]);
    var onReassign = function () {
        setIsRecommendationsApproved(false);
        setRecommendationsReassignModalState({
            visible: true,
            recommendations: modalRecommendations,
            onSave: function (data) {
                setReassignedRecommendations(data);
                var updatedRecommendations = [];
                data.faultRecommendations.map(function (faultRecommendation) {
                    faultRecommendation.recommendations.map(function (recommendation) {
                        updatedRecommendations.push({
                            id: 0,
                            action_item_id: 0,
                            component_type_id: faultRecommendation.componentType,
                            sub_type_id: faultRecommendation.subType,
                            fault_id: faultRecommendation.fault,
                            recommendation_id: recommendation,
                            status: 0,
                            user_id: 0,
                            severity: faultRecommendation.severity,
                            componentType: reportEditorData.componentTypes.find(function (componentType) { return componentType.id === faultRecommendation.componentType; }),
                            subType: reportEditorData.subTypes.find(function (subType) { return subType.id === faultRecommendation.subType; }),
                            fault: reportEditorData.faults.find(function (fault) { return fault.id === faultRecommendation.fault; }),
                            recommendation: reportEditorData.recommendations.find(function (item) { return item.id === recommendation; }),
                        });
                    });
                });
                setModalRecommendations(updatedRecommendations);
                setInternalComment(ReactDOMServer.renderToStaticMarkup((_jsxs("div", { style: { width: '90%', fontSize: '14px' }, children: [_jsx("div", { className: "mb-2", children: _jsx("b", { children: "The Primary Reason:" }) }), _jsx(SelectedRecommendations, { recommendations: recommendations }), _jsx("div", { className: "mb-2 mt-2", children: _jsx("b", { children: "Reassigned To:" }) }), _jsx(SelectedRecommendations, { recommendations: updatedRecommendations })] }))));
            },
        });
    };
    return modalRecommendations.length > 0 ? (_jsxs("div", { className: "mt-4 mb-4", children: [_jsx(Label, { className: "fw-bold", children: "Equipment Fault Reason:" }), _jsx(SelectedRecommendations, { recommendations: modalRecommendations }), _jsxs(ButtonGroup, { className: "float-end mt-2", children: [_jsx(Button, { onClick: function () { return setIsRecommendationsApproved(true); }, color: "success", size: "sm", disabled: isRecommendationsApproved, outline: isRecommendationsApproved, children: isRecommendationsApproved ? 'Approved' : 'Approve' }), _jsx(Button, { onClick: onReassign, color: "danger", size: "sm", children: "Reassign" })] })] })) : (_jsx(_Fragment, {}));
};
export default memo(ApproveReassignRecommendations);
