var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { shallow } from 'zustand/shallow';
import { AXIS_Z, READING_TYPES } from '../../../../constants';
import { isSupportZAxis } from '../../../../widgets/sensor';
import { useChartSettingsQuery, } from '../../../services/charts/queries/useChartSettingsQuery';
import { ChartsStore } from '../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { useChartSettingsActions } from '../../../store/charts/useChartSettingsStore';
import { selectIsVisibleFooterBar } from '../../../store/charts/useFooterBarStore';
import './FooterBar.scss';
var FooterBar = function () {
    var _a = useState([]), list = _a[0], setList = _a[1];
    var _b = ChartsStore(function (state) { return ({
        setStates: state.setStates,
        scaleUrlParam: state.scaleUrlParam,
    }); }, shallow), setStates = _b.setStates, scaleUrlParam = _b.scaleUrlParam;
    var _c = InstallationPointStore(function (state) { return ({
        readingTypes: state.readingTypes,
        installationPoint: state.installationPoint,
    }); }, shallow), readingTypes = _c.readingTypes, installationPoint = _c.installationPoint;
    var isVisibleFooterBar = selectIsVisibleFooterBar();
    var setChartSorting = useChartSettingsActions().setChartSorting;
    var _d = useChartSettingsQuery(), isLoading = _d.isLoading, isFetching = _d.isFetching, chartSettingsData = _d.data;
    var chartSortingQuery = chartSettingsData.chartSorting, chartDefaultZoomDaysQuery = chartSettingsData.chartDefaultZoomDays;
    var _e = useChartSettingsActions(), setChartDefaultZoomDays = _e.setChartDefaultZoomDays, setChartZoomDays = _e.setChartZoomDays;
    useEffect(function () {
        if (isLoading || isFetching) {
            return;
        }
        setStates({
            countDays: scaleUrlParam ? scaleUrlParam : chartDefaultZoomDaysQuery !== null && chartDefaultZoomDaysQuery !== void 0 ? chartDefaultZoomDaysQuery : 14,
            scaleUrlParam: null,
        });
        setChartDefaultZoomDays(chartDefaultZoomDaysQuery);
        setChartZoomDays(scaleUrlParam ? scaleUrlParam : chartDefaultZoomDaysQuery !== null && chartDefaultZoomDaysQuery !== void 0 ? chartDefaultZoomDaysQuery : 14);
        setList(chartSortingQuery);
    }, [isFetching]);
    var onChangeVisible = function (val, newVisible) {
        setList(list.map(function (el) {
            if (el.chartIdentifier !== val) {
                return el;
            }
            return __assign(__assign({}, el), { isVisible: newVisible });
        }));
    };
    useEffect(function () {
        if (!list.length) {
            return;
        }
        setChartSorting(list.map(function (_a) {
            var chartIdentifier = _a.chartIdentifier, isVisible = _a.isVisible;
            return {
                chartIdentifier: chartIdentifier,
                isVisible: isVisible,
            };
        }));
    }, [list]);
    var sensor = _get(installationPoint, 'sensor') || {};
    var isValid = function (chartIdentifier) {
        return _get(readingTypes, [chartIdentifier]) &&
            !(!isSupportZAxis(+(sensor === null || sensor === void 0 ? void 0 : sensor.version_type)) && +_get(readingTypes, [chartIdentifier, 'axisId'], 1) === AXIS_Z);
    };
    if (!list.length) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(_Fragment, { children: _jsx(ReactSortable, { animation: 250, className: "charts-footer-bar ".concat(isVisibleFooterBar ? 'active' : ''), ghostClass: "active", list: list, setList: setList, children: list.map(function (_a) {
                var _b, _c;
                var chartIdentifier = _a.chartIdentifier, isVisible = _a.isVisible;
                return (_jsxs("label", { className: "".concat(isVisible ? '' : 'disabled', " ").concat(isValid(chartIdentifier) ? '' : 'invalid'), children: [_jsx("input", { type: "checkbox", onChange: function () { return onChangeVisible(chartIdentifier, !isVisible); }, checked: isVisible }), ((_b = READING_TYPES[+chartIdentifier]) === null || _b === void 0 ? void 0 : _b.titleShort) || '', ' ', ((_c = READING_TYPES[+chartIdentifier]) === null || _c === void 0 ? void 0 : _c.axis) || ''] }, chartIdentifier));
            }) }) }));
};
export default memo(FooterBar);
