import Api from '../../api';
export default {
    getAnalystNotesList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/analyst-notes', options);
    },
    deleteAnalystNote: function (id, body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post("all-analytics/analyst-notes/delete/".concat(id), body, options);
    },
    deleteAnalystNoteFile: function (id, body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post("all-analytics/analyst-notes/delete-file/".concat(id), body, options);
    },
    createAnalystNote: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/analyst-notes/create', body, options);
    },
    updateAnalystNote: function (body, options) {
        if (body === void 0) { body = {}; }
        if (options === void 0) { options = {}; }
        return Api.post('all-analytics/analyst-notes/update', body, options);
    },
};
