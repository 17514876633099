var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import { get as _get } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { FFT_ALERT_TYPES, FREQUENCY_TYPES, SERVICE_SELF } from '../../../../../../../constants';
import { isFftChart, isTwfChart } from '../../../../../../helper/chart';
import { ChartFeaturesStore } from '../../../../../../store/charts/chartsContent/ChartFeaturesStore';
import { FftChartsStore } from '../../../../../../store/charts/chartsContent/FftChartsStore';
import { FrequencyConverter } from '../../../features/fftTwf/frequencyConverter';
export var collectShapes = function (fftAlert, fftBandFrequencyType, currentSpeed) {
    if (fftBandFrequencyType === void 0) { fftBandFrequencyType = null; }
    if (!(fftAlert === null || fftAlert === void 0 ? void 0 : fftAlert.value)) {
        return [];
    }
    fftBandFrequencyType = fftBandFrequencyType || fftAlert.fft_band_frequency_type || FREQUENCY_TYPES.HZ;
    if (currentSpeed === 0 && fftBandFrequencyType === 'Orders') {
        return [];
    }
    var processedAlert = Object.assign({}, {
        alertLevel: fftAlert.alertLevel,
        value: fftAlert.value,
        fft_band_from: FrequencyConverter.fromType(fftBandFrequencyType, fftAlert.fft_band_from, currentSpeed).toHz().value,
        fft_band_to: FrequencyConverter.fromType(fftBandFrequencyType, fftAlert.fft_band_to, currentSpeed).toHz().value,
    }), color = new Highcharts.Color(processedAlert.alertLevel.color)
        .setOpacity(fftAlert.service === SERVICE_SELF ? 0.5 : 1)
        .get(), colorDashLine = new Highcharts.Color(processedAlert.alertLevel.color)
        .setOpacity(fftAlert.service === SERVICE_SELF ? 0.3 : 0.6)
        .get(), colorBackground = new Highcharts.Color(processedAlert.alertLevel.color)
        .setOpacity(fftAlert.service === SERVICE_SELF ? 0.05 : 0.1)
        .get();
    return [
        {
            type: 'path',
            dashStyle: fftAlert.service === SERVICE_SELF ? 'LongDash' : 'Solid',
            strokeWidth: 4,
            stroke: color,
            points: [
                { x: processedAlert.fft_band_from, y: processedAlert.value, xAxis: 0, yAxis: 0 },
                { x: processedAlert.fft_band_to, y: processedAlert.value, xAxis: 0, yAxis: 0 },
            ],
            alertGroup: 'band',
        },
        {
            type: 'path',
            stroke: false,
            fill: colorBackground,
            points: [
                { x: processedAlert.fft_band_from, y: 0, xAxis: 0, yAxis: 0 },
                { x: processedAlert.fft_band_from, y: processedAlert.value, xAxis: 0, yAxis: 0 },
                { x: processedAlert.fft_band_to, y: processedAlert.value, xAxis: 0, yAxis: 0 },
                { x: processedAlert.fft_band_to, y: 0, xAxis: 0, yAxis: 0 },
            ],
            alertGroup: 'band',
        },
        {
            type: 'path',
            dashStyle: 'LongDashDot',
            stroke: colorDashLine,
            points: [
                { x: processedAlert.fft_band_to, y: 0, xAxis: 0, yAxis: 0 },
                { x: processedAlert.fft_band_to, y: processedAlert.value, xAxis: 0, yAxis: 0 },
            ],
            alertGroup: 'band',
        },
        {
            type: 'path',
            dashStyle: 'LongDashDot',
            stroke: colorDashLine,
            points: [
                { x: processedAlert.fft_band_from, y: 0, xAxis: 0, yAxis: 0 },
                { x: processedAlert.fft_band_from, y: processedAlert.value, xAxis: 0, yAxis: 0 },
            ],
            alertGroup: 'band',
        },
    ];
};
export var isValidBand = function (bandOptions, currentSpeed) {
    if (currentSpeed === 0 && bandOptions.fft_band_frequency_type === 'Orders') {
        return false;
    }
    return (bandOptions.fft_band_from !== null &&
        bandOptions.fft_band_to !== null &&
        bandOptions.value !== null &&
        bandOptions.value > 0 &&
        +bandOptions.fft_band_to > +bandOptions.fft_band_from);
};
export var getMaxBand = function (chartIdentifier, fftAlerts) {
    if (!isFftChart(chartIdentifier)) {
        return null;
    }
    fftAlerts = fftAlerts.filter(function (fftAlert) { return fftAlert.fft_alert_type === FFT_ALERT_TYPES.BAND; });
    if (fftAlerts.length === 0) {
        return null;
    }
    return Math.max.apply(Math, fftAlerts.map(function (item) { return item.value; }));
};
export var getBandsAnnotations = function (chartIdentifier, fftAlerts, currentSpeed) {
    if (!isFftChart(chartIdentifier)) {
        return [];
    }
    fftAlerts = fftAlerts.filter(function (fftAlert) { return fftAlert.fft_alert_type === FFT_ALERT_TYPES.BAND; });
    if (fftAlerts.length === 0) {
        return [];
    }
    var shapes = [];
    for (var _i = 0, fftAlerts_1 = fftAlerts; _i < fftAlerts_1.length; _i++) {
        var fftAlert = fftAlerts_1[_i];
        if (isValidBand(fftAlert, currentSpeed)) {
            shapes = __spreadArray(__spreadArray([], shapes, true), collectShapes(fftAlert, null, currentSpeed), true);
        }
    }
    return [
        {
            shapes: shapes,
            draggable: '',
            type: 'band',
            visible: false,
            zIndex: 100,
        },
    ];
};
export var BandAlarmsDropdown = function (_a) {
    var chartIdentifier = _a.chartIdentifier, dispatch = _a.dispatch, isVisibleBands = _a.isVisibleBands, width = _a.width, isDisabled = _a.isDisabled;
    if (isTwfChart(chartIdentifier)) {
        return _jsx(React.Fragment, {});
    }
    var onToggleIsVisibleBands = function () {
        dispatch({
            type: 'setState',
            state: {
                isVisibleBands: !isVisibleBands,
            },
        });
    };
    var _b = useState(false), isVisibleDropdown = _b[0], setIsVisibleDropdown = _b[1];
    var fftAlerts = FftChartsStore(function (state) { return ({
        fftAlerts: state.fftAlerts,
    }); }, shallow).fftAlerts;
    var chartAlerts = _get(fftAlerts, chartIdentifier, []).filter(function (fftAlert) { return fftAlert.fft_alert_type === FFT_ALERT_TYPES.BAND; });
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow).setStatesChartFeaturesStore;
    var onManage = useCallback(function () {
        setStatesChartFeaturesStore({
            bandAlarmsFormModal: {
                visible: true,
                band: {},
                chartIdentifier: chartIdentifier,
            },
        });
    }, [chartIdentifier]);
    var indicatorColor = useMemo(function () {
        var color = 'green';
        chartAlerts.forEach(function (chartAlert) {
            var _a;
            if (color !== 'red' && chartAlert.hasTriggeredAlerts) {
                color = ((_a = chartAlert.alertLevel) === null || _a === void 0 ? void 0 : _a.name) === 'Caution' ? 'yellow' : 'red';
            }
        });
        return color;
    }, [chartAlerts]);
    return (_jsx("div", { className: "chart-btn", children: _jsx("div", { className: "band-alarms-dropdown chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), style: { width: width }, children: _jsxs(Dropdown, { disabled: isDisabled, direction: "down", isOpen: isVisibleDropdown, toggle: function () { return setIsVisibleDropdown(!isVisibleDropdown); }, children: [_jsx(DropdownToggle, { color: "secondary", size: "sm", caret: true, children: _jsxs("span", { className: "band-alarms-dropdown-toggle-link", children: ["Band Alarms", chartAlerts.length > 0 ? (_jsx("span", { className: "indicator indicator-full-size indicator-".concat(indicatorColor) })) : (_jsx(_Fragment, {}))] }) }), _jsxs(DropdownMenu, { children: [chartAlerts.length > 0 && (_jsxs(React.Fragment, { children: [_jsx(DropdownItem, { className: "btn-sm", onClick: onToggleIsVisibleBands, children: isVisibleBands ? 'Hide' : 'Show' }), _jsx(DropdownItem, { divider: true })] })), _jsx(DropdownItem, { className: "btn-sm", onClick: onManage, children: "Manage Band Alarms..." })] })] }) }) }));
};
