import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTitle } from '../../../../customHooks';
import BaseActionItemsPage from '../../../components/actionItem/list/BasePage';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../../store/SelectedLocationsStore';
var HardwareActionListPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b;
    useTitle('Network Issues');
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    return (_jsx(BaseActionItemsPage, { refreshFlag: refreshFlag, installationPointId: null, selectedCustomers: selectedCustomers, selectedLocations: selectedLocations, isHardwarePage: true, title: "Network Issues", tableName: 'actionListHardwareTable', isActive: true }));
};
export default HardwareActionListPage;
