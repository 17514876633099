import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { FaRegLightbulb } from 'react-icons/fa';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { READING_TYPES } from '../../../../constants';
import { hasAlertConditionsAction } from '../../../features/suggestedLevels';
import { getUoms } from '../../../helper/chart';
import { useAnalystNotesCommentsByReadingTypeQuery, } from '../../../services/charts/useAnalystNotesCommnetsByReadingTypeQuery';
import { selectMeasure, selectPersonalSettingMeasure } from '../../../store/global/useGlobalStore';
import { selectIsVisibleAnalystNotes, useSuggestedLevelModalActions, } from '../../../store/suggestedLevel/useSuggestedLevelModalStore';
var AlertIndicator = function (_a) {
    var readingType = _a.readingType, alertLevelsSettings = _a.alertLevelsSettings, installationPoint = _a.installationPoint;
    var setIsVisibleAnalystNotes = useSuggestedLevelModalActions().setIsVisibleAnalystNotes;
    var isVisible = selectIsVisibleAnalystNotes();
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var data = useAnalystNotesCommentsByReadingTypeQuery({
        installationPointIds: [installationPoint.id],
        readingTypeIds: [readingType],
    }).data;
    return (_jsxs("div", { className: "axis-name h4 mb-2 mt-1 position-relative", children: [_jsx("span", { className: "me-2", children: _get(READING_TYPES, [readingType, 'axis']) }), hasAlertConditionsAction(alertLevelsSettings, readingType) || data.length ? (_jsxs(_Fragment, { children: [_jsx(Badge, { id: "alert-conditions-settings-label-".concat(readingType), className: "alert-conditions-settings-label label-circle", style: { position: 'initial' }, color: "warning", onClick: function () { return setIsVisibleAnalystNotes(!isVisible); }, children: _jsx(FaRegLightbulb, { size: 15 }) }), _jsxs(UncontrolledTooltip, { className: "alert-conditions-settings-label-tooltip", placement: "bottom", target: "alert-conditions-settings-label-".concat(readingType), children: [hasAlertConditionsAction(alertLevelsSettings, readingType) ? (_jsxs(React.Fragment, { children: [Object.values(alertLevelsSettings).length > 1 ? (_jsxs("div", { children: [_get(READING_TYPES, [readingType, 'title'], ''), ' ', _get(READING_TYPES, [readingType, 'axis'], '')] })) : (_jsx(_Fragment, {})), alertLevelsSettings[readingType].cautionSettings.is_ai ||
                                        alertLevelsSettings[readingType].cautionSettings.is_pre_ai ? (_jsxs("div", { children: [alertLevelsSettings[readingType].cautionSettings.username, ": Caution", ' ', alertLevelsSettings[readingType].cautionSettings.value, ' ', getUoms(readingType, measure, personalSettingMeasure), ' ', alertLevelsSettings[readingType].cautionSettings.trigger_time &&
                                                alertLevelsSettings[readingType].cautionSettings.trigger_time !== 0
                                                ? "".concat(alertLevelsSettings[readingType].cautionSettings.trigger_time, " minutes")
                                                : ''] })) : (_jsx(_Fragment, {})), alertLevelsSettings[readingType].warningSettings.is_ai ||
                                        alertLevelsSettings[readingType].warningSettings.is_pre_ai ? (_jsxs("div", { children: [alertLevelsSettings[readingType].warningSettings.username, ": Warning", ' ', alertLevelsSettings[readingType].warningSettings.value, ' ', getUoms(readingType, measure, personalSettingMeasure), ' ', alertLevelsSettings[readingType].warningSettings.trigger_time &&
                                                alertLevelsSettings[readingType].warningSettings.trigger_time !== 0
                                                ? "".concat(alertLevelsSettings[readingType].warningSettings.trigger_time, " minutes")
                                                : ''] })) : (_jsx(_Fragment, {}))] }, "alert-conditions-settings-label-".concat(readingType))) : (_jsx(_Fragment, {})), data.map(function (comment) { return (_jsxs("div", { children: ["Has analyst notes by ", comment.user_name] }, comment.id)); })] })] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(AlertIndicator);
