import { createStore } from '../createStore';
var useViewedLogStore = createStore(function (set) { return ({
    isCustomerDashboard: false,
    isHideViewAi: true,
    actions: {
        setIsCustomerDashboard: function (isCustomerDashboard) { return set({ isCustomerDashboard: isCustomerDashboard }); },
        setIsHideViewAi: function (isHideViewAi) { return set({ isHideViewAi: isHideViewAi }); },
    },
}); });
export var useViewedLogActions = function () {
    return useViewedLogStore(function (state) { return state.actions; });
};
export var selectIsCustomerDashboard = function () {
    return useViewedLogStore(function (state) { return state.isCustomerDashboard; });
};
export var selectIsHideViewAi = function () {
    return useViewedLogStore(function (state) { return state.isHideViewAi; });
};
