import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import moment from 'moment';
import React, { memo } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../../../../store/charts/chartsContent/ChartsStore';
import { FftImpactVueChartsStore } from '../../../../../../store/charts/chartsContent/FftImpactVueChartsStore';
import '../../../../../../styles/charts/chartsWrapper/LabelsChartActions.scss';
import { FFTIdIndicator } from '../../shared/labelsChartActionsIndicators/FFTIdIndicators';
var LabelsChartActions = function (_a) {
    var chartIdentifier = _a.chartIdentifier;
    var _b = FftImpactVueChartsStore(function (state) { return ({
        data: state.data,
        readingFftIds: state.readingFftIds,
        fftTimestampDates: state.fftTimestampDates,
    }); }, shallow), data = _b.data, readingFftIds = _b.readingFftIds, fftTimestampDates = _b.fftTimestampDates;
    var isSelectChartVisible = ChartsStore(function (state) { return ({
        isSelectChartVisible: state.isSelectChartVisible,
    }); }, shallow).isSelectChartVisible;
    var fftTimestamps = _get(data, "fftTimestamps[1][".concat(moment(fftTimestampDates[chartIdentifier]).format('YYYY-MM-DD'), "]"));
    var readingId = data && readingFftIds
        ? Object.values(readingFftIds).length
            ? readingFftIds[chartIdentifier]
            : fftTimestamps && fftTimestamps.length
                ? +fftTimestamps[fftTimestamps.length - 1].readingFFT_id
                : null
        : null;
    return (_jsx("div", { className: "labels-chart-actions", children: !isSelectChartVisible ? (_jsx(_Fragment, { children: readingId ? _jsx(FFTIdIndicator, { chartIdentifier: chartIdentifier, readingId: readingId }) : _jsx(_Fragment, {}) })) : (_jsx(_Fragment, {})) }));
};
export default memo(LabelsChartActions);
