var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import amazonActionItemEndpoints from './amazon/actionItem';
import amazonActionListEndpoints from './amazon/actionList';
import amazonAlertConditionEndpoints from './amazon/alertCondition';
import amazonAlertsEndpoints from './amazon/alerts';
import amazonAnalystLogEndpoints from './amazon/analystLog';
import amazonAnalystNotesEndpoints from './amazon/analystNotes';
import amazonAreas from './amazon/area';
import amazonAutoAlertConditionsEndpoints from './amazon/autoAlertConditions';
import amazonBadDataEndpoints from './amazon/badData';
import amazonChartEndpoints from './amazon/chart';
import amazonDashboardEndpoints from './amazon/dashboard';
import amazonEquipmentEndpoints from './amazon/equipment';
import amazonFacilityEndpoints from './amazon/facility';
import amazonFaultFrequencyEndpoints from './amazon/faultFrequency';
import amazonInListEndpoints from './amazon/inList';
import amazonInstallationPointEndpoints from './amazon/installationPoint';
import amazonLogEndpoints from './amazon/log';
import amazonMachineInfoEndpoints from './amazon/machineInfo';
import amazonMlSuggestedAlarmAdjustment from './amazon/mlSuggestedAlarmAdjustment';
import amazonPreActionList from './amazon/preActionList';
import amazonPreActionListAnalystLog from './amazon/preAiAnalystLog';
import amazonRequestOnDemandEndpoints from './amazon/requestOnDemand';
import amazonSlaSettings from './amazon/slaSettings';
import amazonStatisticEndpoints from './amazon/statistic';
import amazonStructureEndpoints from './amazon/structure';
import amazonSuggestedLevelsEndpoints from './amazon/suggestedLevels';
import amazonTwfDataActionListEndpoints from './amazon/twfDataActionList';
import amazonActivityEndpoints from './amazon/userActivity';
import amazonWatchListEndpoints from './amazon/watchList';
import amazonWithoutAlarmLevelsEndpoints from './amazon/withoutAlarmLevels';
import amazonZones from './amazon/zone';
import actionItemEndpoints from './remastered/actionItem';
import actionListEndpoints from './remastered/actionList';
import alertConditionEndpoints from './remastered/alertCondition';
import alertsEndpoints from './remastered/alerts';
import analystLogEndpoints from './remastered/analystLog';
import analystNotesEndpoints from './remastered/analystNotes';
import autoAlertConditionsEndpoints from './remastered/autoAlertConditions';
import badDataEndpoints from './remastered/badData';
import chartEndpoints from './remastered/chart';
import customerEndpoints from './remastered/customer';
import dashboardEndpoints from './remastered/dashboard';
import equipmentEndpoints from './remastered/equipment';
import faultFrequencyEndpoints from './remastered/faultFrequency';
import fileManageEndpoints from './remastered/fileManage';
import idleThresholdLogEndpoints from './remastered/idleThresholdLog';
import inListEndpoints from './remastered/inList';
import installationPointEndpoints from './remastered/installationPoint';
import logEndpoints from './remastered/log';
import machineInfoEndpoints from './remastered/machineInfo';
import mlSuggestedAlarmAdjustment from './remastered/mlSuggestedAlarmAdjustment';
import oauthClientsEndpoints from './remastered/oauthClients';
import preActionListEndpoints from './remastered/preActionList';
import preAiAnalystLogEndpoints from './remastered/preAiAnalystLog';
import requestOnDemandEndpoints from './remastered/requestOnDemand';
import runningSpeedLogEndpoints from './remastered/runningSpeedLog';
import sensorEndpoints from './remastered/sensor';
import slaSettings from './remastered/slaSettings';
import statisticEndpoints from './remastered/statistic';
import structureEndpoints from './remastered/structure';
import suggestedLevelsEndpoints from './remastered/suggestedLevels';
import twfDataActionListEndpoints from './remastered/twfDataActionList';
import userActivityEndpoints from './remastered/userActivity';
import watchListEndpoints from './remastered/watchList';
import whatsNewEndpoints from './remastered/whatsNew';
import withoutAlarmLevelsEndpoints from './remastered/withoutAlarmLevels';
import ssoUserEndpoints from './sso/user';
var Endpoints = {
    sso: __assign({}, ssoUserEndpoints),
    remastered: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, oauthClientsEndpoints), customerEndpoints), userActivityEndpoints), preActionListEndpoints), statisticEndpoints), actionListEndpoints), watchListEndpoints), autoAlertConditionsEndpoints), suggestedLevelsEndpoints), fileManageEndpoints), inListEndpoints), withoutAlarmLevelsEndpoints), badDataEndpoints), alertsEndpoints), chartEndpoints), whatsNewEndpoints), equipmentEndpoints), sensorEndpoints), alertConditionEndpoints), machineInfoEndpoints), structureEndpoints), requestOnDemandEndpoints), logEndpoints), analystNotesEndpoints), analystLogEndpoints), installationPointEndpoints), runningSpeedLogEndpoints), idleThresholdLogEndpoints), preAiAnalystLogEndpoints), twfDataActionListEndpoints), dashboardEndpoints), actionItemEndpoints), slaSettings), mlSuggestedAlarmAdjustment), faultFrequencyEndpoints),
    amazon: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, amazonFacilityEndpoints), amazonWatchListEndpoints), amazonBadDataEndpoints), amazonAnalystNotesEndpoints), amazonInstallationPointEndpoints), amazonStructureEndpoints), amazonPreActionList), amazonPreActionListAnalystLog), amazonActionListEndpoints), amazonTwfDataActionListEndpoints), amazonWithoutAlarmLevelsEndpoints), amazonAlertsEndpoints), amazonRequestOnDemandEndpoints), amazonActionItemEndpoints), amazonSuggestedLevelsEndpoints), amazonAlertConditionEndpoints), amazonLogEndpoints), amazonActivityEndpoints), amazonStatisticEndpoints), amazonDashboardEndpoints), amazonInListEndpoints), amazonChartEndpoints), amazonEquipmentEndpoints), amazonAnalystLogEndpoints), amazonZones), amazonAreas), amazonMachineInfoEndpoints), amazonAutoAlertConditionsEndpoints), amazonSlaSettings), amazonMlSuggestedAlarmAdjustment), amazonFaultFrequencyEndpoints),
};
export default Endpoints;
