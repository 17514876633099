import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import useContentSectionToggle from '../../../hooks/AIPreAi/useContentSectionToggle';
import SectionBtnToggle from '../SectionBtnToggle';
import ActionItemRow from './components/ActionItemRow';
import './index.scss';
var DEFAULT_DIRECTION = 0;
var ActiveActionItemsOnEquipment = function (_a) {
    var existingActionItems = _a.existingActionItems, statusRow = _a.statusRow, archiveStates = _a.archiveStates, localStorageKey = _a.localStorageKey;
    var id = useParams().id;
    var activeItems = existingActionItems.filter(function (item) {
        if (!item.id) {
            return [];
        }
        return !archiveStates.includes(+item.state) && (id ? +id !== item.id : true);
    });
    var _b = useContentSectionToggle(localStorageKey, DEFAULT_DIRECTION), isContentVisible = _b.isContentVisible, storageStateMemory = _b.storageStateMemory, setIsContentVisible = _b.setIsContentVisible, onSaveAsDefaultSize = _b.onSaveAsDefaultSize;
    if (!activeItems.length) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: "ai-on-equipment default-ai-block", children: [_jsxs("div", { className: "default-ai-block-header", children: [_jsx("div", { className: "default-ai-block-header-title", children: "Action Items Already Exists On This Equipment" }), _jsx(SectionBtnToggle, { isContentVisible: isContentVisible, storageStateMemory: storageStateMemory, setIsContentVisible: setIsContentVisible, onSaveAsDefaultSize: onSaveAsDefaultSize, localStorageKey: localStorageKey })] }), _jsx(_Fragment, { children: isContentVisible ? (_jsx("div", { className: "default-ai-block-content", children: activeItems.map(function (activeItem) { return (_jsx(React.Fragment, { children: _jsx(ActionItemRow, { installationPointCurrent: null, existingActionItem: activeItem, statusRow: statusRow }) }, activeItem.id)); }) })) : (_jsx(_Fragment, {})) })] }));
};
export default memo(ActiveActionItemsOnEquipment);
