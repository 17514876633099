import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTitle } from '../../../../customHooks';
import BaseActionItemsPage from '../../../components/actionItem/list/BasePage';
var ActiveActionListPage = function (_a) {
    var _b = _a.refreshFlag, refreshFlag = _b === void 0 ? null : _b, _c = _a.selectedFacility, selectedFacility = _c === void 0 ? null : _c;
    useTitle('Action List');
    return (_jsx(BaseActionItemsPage, { refreshFlag: refreshFlag, installationPointId: null, selectedFacility: selectedFacility, title: "Action list", isActive: true, tableName: 'amazonActionListActiveTable' }));
};
export default ActiveActionListPage;
