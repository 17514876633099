import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import React from 'react';
import { Badge } from 'reactstrap';
import TooltipBlockData from '../components/TooltipBlockData/TooltipBlockData';
import { getLabelData } from '../features';
var AlertListLabel = function (_a) {
    var existInList = _a.existInList;
    var data = getLabelData(existInList, 'alertsList');
    return (_jsx(_Fragment, { children: data ? (_jsx("div", { className: "tag-item", children: _jsx(TooltipBlockData, { icon: _jsx(Badge, { color: "warning", children: "Alert" }), children: _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("div", { className: "tooltip-content-key", children: "Time created:" }), "UTC: ", moment(data.value.created_at).format('MM/DD/YYYY, h:mm A') || '---'] }) }) })) : (_jsx(_Fragment, {})) }));
};
export default React.memo(AlertListLabel);
