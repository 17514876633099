import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { AccordionBody } from 'reactstrap';
var AccordionChildren = function (_a) {
    var item = _a.item, index = _a.index, prefix = _a.prefix;
    if (!item.children) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(AccordionBody, { accordionId: item.link, children: _jsx("div", { className: "accordion-body-children-element", children: item.children.map(function (child, childIndex) { return (_jsx("div", { className: _has(child, 'visible') && !child.visible ? 'd-none' : 'd-block', children: _jsxs(NavLink, { onClick: child.onClick, className: function (navData) { return (navData.isActive ? 'is-active' : ''); }, to: prefix + child.link, end: true, children: [_jsx("div", { className: "menu__icon", children: _jsx(FaAngleRight, {}) }), _jsx("div", { className: "menu__title", children: child.title })] }) }, "".concat(index, "_").concat(childIndex))); }) }) }));
};
export default AccordionChildren;
