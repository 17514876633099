import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import 'nouislider/distribute/nouislider.css';
import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, InputGroup, Label } from 'reactstrap';
import { FrequencyConverter } from '../../../components/charts/chartsWrapper/features/fftTwf/frequencyConverter';
var FrequencyRange = function (_a) {
    var setFrequencyRange = _a.setFrequencyRange, units = _a.units, isTrendVisible = _a.isTrendVisible, setIsTrendVisible = _a.setIsTrendVisible, currentSpeed = _a.currentSpeed;
    var _b = useState(''), from = _b[0], setFrom = _b[1];
    var _c = useState(''), to = _c[0], setTo = _c[1];
    useEffect(function () {
        setFrequencyRange({ from: from, to: to });
    }, [from, to]);
    return (_jsxs(FormGroup, { inline: true, className: "ms-2 mt-4", children: [_jsx(Label, { className: "d-inline-block me-2 fft-units-dropdown-toggle-link", children: "From " }), _jsx(Input, { type: "number", min: 0, name: "frequency-range-from", id: "requency-range-from", 
                //@ts-ignore
                value: from !== '' ? parseInt(FrequencyConverter.fromHz(from, currentSpeed).toType(units).value) : '', onChange: function (ev) {
                    return setFrom(ev.target.value
                        ? FrequencyConverter.fromType(units, ev.target.value, currentSpeed).toHz().value
                        : '');
                }, className: "w-20 d-inline-block", bsSize: "sm" }), _jsx(Label, { className: "d-inline-block ms-2 me-2 fft-units-dropdown-toggle-link", children: "To " }), _jsx(InputGroup, { className: "w-20 d-inline-flex", children: _jsx(Input, { type: "number", min: 0, name: "frequency-range-to", id: "requency-range-to", 
                    //@ts-ignore
                    value: to !== '' ? parseInt(FrequencyConverter.fromHz(to, currentSpeed).toType(units).value) : '', onChange: function (ev) {
                        return setTo(ev.target.value
                            ? FrequencyConverter.fromType(units, ev.target.value, currentSpeed).toHz().value
                            : '');
                    }, className: "w-20 d-inline-block", bsSize: "sm" }) }), _jsxs(Button, { className: "chart-btn ms-2", outline: true, color: "secondary", size: "sm", onClick: function () { return setIsTrendVisible(!isTrendVisible); }, children: [isTrendVisible ? 'Hide' : 'Show', " Trend"] })] }));
};
export default FrequencyRange;
