import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import React, { memo, useContext, useEffect, useReducer } from 'react';
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from 'react-icons/tb';
import { useLocation } from 'react-router-dom';
import { AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap';
import SelectsGroupAmazon from '../../../amazon/components/analystNotes/SelectsGroup';
import { ANALYST_NOTES_TAGS as AMAZON_ANALYST_NOTES_TAGS } from '../../../amazon/constants';
import Endpoints from '../../../api/endpoints/endpoints';
import { SYSTEM_TYPES_DATA, SYSTEM_TYPE_AMAZON } from '../../../constants';
import SelectsGroupRemastered from '../../../remastered/components/analystNotes/SelectsGroup';
import { ANALYST_NOTES_TAGS as REMASTERED_ANALYST_NOTES_TAGS } from '../../../remastered/constants';
import DefaultTooltip from '../../../shared/components/DefaultTooltip';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { getIsBlockedActionOnAIPreAIPage } from '../../../shared/helper/commonHelper';
import { reducer } from '../../../shared/helper/reducer';
import FormAnalystNoteModal from '../../../shared/modals/analystNotes/FormAnalystNoteModal';
import { useAnalystNotesCreateMutation } from '../../../shared/services/notes/analystNotes/useAnalystNotesCreateMutation';
import { useAnalystNotesDeleteAttachmentMutation } from '../../../shared/services/notes/analystNotes/useAnalystNotesDeleteAttachmentMutation';
import { useAnalystNotesUpdateMutation } from '../../../shared/services/notes/analystNotes/useAnalystNotesUpdateMutation';
import { selectNote, useAnalystNotesModalsActions, } from '../../../shared/store/analystNotes/useAnalystNotesFormModalStore';
import AccordionChildren from './components/AccordionChildren';
import ExternalLink from './components/ExternalLink';
import SimpleLink from './components/SimpleLink';
import { configMenu } from './config';
import { fetchCountNotViewed } from './features';
import './index.scss';
var initialState = {
    notViewedNews: 0,
};
var Sidebar = function (_a) {
    var setFullSizeSidebar = _a.setFullSizeSidebar, isFullSizeSidebar = _a.isFullSizeSidebar;
    var pathname = useLocation().pathname;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var isAmazonSelected = selectedSystemType === SYSTEM_TYPE_AMAZON;
    var _b = useAnalystNotesModalsActions(), setIsVisibleModal = _b.setIsVisibleModal, resetNote = _b.resetNote;
    var _c = useAnalystNotesDeleteAttachmentMutation(), deleteAttachmentMutate = _c.mutate, deleteAttachmentIsLoading = _c.isPending;
    var _d = useAnalystNotesCreateMutation(), createMutate = _d.mutate, createIsLoading = _d.isPending;
    var _e = useAnalystNotesUpdateMutation(), updateMutate = _e.mutate, updateIsLoading = _e.isPending;
    var _f = useReducer(reducer, initialState), state = _f[0], dispatch = _f[1];
    var isBlockedToggleSidebarSize = getIsBlockedActionOnAIPreAIPage(pathname);
    var note = selectNote();
    var endpoint = function (systemType) {
        return Endpoints.remastered.getCountNotViewedNews({
            query: {
                systemType: systemType,
            },
        });
    };
    useEffect(function () {
        fetchCountNotViewed(dispatch, function () { return endpoint(selectedSystemType); });
    }, [selectedSystemType]);
    var treeArray = configMenu(state, selectedSystemType, setIsVisibleModal, resetNote);
    if (pathname.includes('/dashboard')) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: "sidebar ".concat(isFullSizeSidebar && !isBlockedToggleSidebarSize ? '' : 'sidebar-hidden'), children: [_jsx("div", { className: "menu", children: treeArray.map(function (item, index) {
                    var _a;
                    return (_jsx(React.Fragment, { children: ((_a = item.visible) !== null && _a !== void 0 ? _a : true) ? (_jsx(_Fragment, { children: item.children && item.children.length ? (_jsx("div", { className: "menu__accordion", children: _jsx(UncontrolledAccordion, { stayOpen: isFullSizeSidebar && !isBlockedToggleSidebarSize, defaultOpen: _has(item, 'isOpen') ? [item.link] : [], children: _jsxs(AccordionItem, { children: [isFullSizeSidebar && !isBlockedToggleSidebarSize ? (_jsxs(AccordionHeader, { targetId: item.link, children: [_jsx("div", { className: "menu__icon", children: item.icon() }), _jsx("div", { className: "menu__title", children: item.title })] })) : (_jsx("div", { className: "accordion-header", children: _jsx("div", { className: "accordion-button", children: _jsx("div", { className: "menu__icon", children: item.icon() }) }) })), _jsx(AccordionChildren, { index: index, item: item, prefix: prefix })] }) }) })) : (_jsx("div", { className: "menu__link", children: item.externalLink ? (_jsx(ExternalLink, { isFullSizeSidebar: isFullSizeSidebar, index: index, item: item })) : (_jsx(SimpleLink, { isFullSizeSidebar: isFullSizeSidebar, index: index, item: item, prefix: prefix })) })) })) : (_jsx(_Fragment, {})) }, "parent-".concat(index)));
                }) }), _jsx("div", { className: "menu-toggle-btn ".concat(isBlockedToggleSidebarSize ? 'disabled' : ''), "data-tooltip-id": "menu-toggle-btn", onClick: function () {
                    if (isBlockedToggleSidebarSize) {
                        return;
                    }
                    setFullSizeSidebar(!isFullSizeSidebar);
                }, children: isFullSizeSidebar && !isBlockedToggleSidebarSize ? (_jsxs(_Fragment, { children: [_jsx(TbLayoutSidebarLeftCollapse, {}), " Hide menu"] })) : (_jsx(TbLayoutSidebarLeftExpand, {})) }), isFullSizeSidebar ? (_jsx(_Fragment, {})) : (_jsx(DefaultTooltip, { place: "right", openEvents: { mouseenter: true }, id: "menu-toggle-btn", children: isBlockedToggleSidebarSize ? 'It is forbidden to toggle menu size on this page' : 'Show menu' })), _jsx(FormAnalystNoteModal, { tagsList: isAmazonSelected ? AMAZON_ANALYST_NOTES_TAGS : REMASTERED_ANALYST_NOTES_TAGS, deleteAttachmentMutate: deleteAttachmentMutate, deleteAttachmentIsLoading: deleteAttachmentIsLoading, saveMutate: note.id ? updateMutate : createMutate, saveIsLoading: note.id ? updateIsLoading : createIsLoading, children: isAmazonSelected ? _jsx(SelectsGroupAmazon, {}) : _jsx(SelectsGroupRemastered, {}) })] }));
};
export default memo(Sidebar);
