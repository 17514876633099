import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '../../../../../../store/charts/chartsContent/ChartsStore';
import { FftChartsStore } from '../../../../../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../../../../../store/charts/chartsContent/InstallationPointStore';
import '../../../../../../styles/charts/chartsWrapper/LabelsChartActions.scss';
import { FFTIdIndicator } from '../../shared/labelsChartActionsIndicators/FFTIdIndicators';
import WakeOnShakeIndicator from '../../shared/labelsChartActionsIndicators/WakeOnShakeIndicator';
var LabelsChartActions = function (_a) {
    var _b;
    var chartIdentifier = _a.chartIdentifier;
    var _c = FftChartsStore(function (state) { return ({
        data: state.data,
        readingFftIds: state.readingFftIds,
    }); }, shallow), data = _c.data, readingFftIds = _c.readingFftIds;
    var isSelectChartVisible = ChartsStore(function (state) { return ({
        isSelectChartVisible: state.isSelectChartVisible,
    }); }, shallow).isSelectChartVisible;
    var wosTrigger = InstallationPointStore(function (state) { return state.wosTrigger; });
    var readingId = data && readingFftIds
        ? Object.values(readingFftIds).length
            ? readingFftIds[chartIdentifier]
            : (_b = _get(data, 'readingIds')) === null || _b === void 0 ? void 0 : _b[chartIdentifier]
        : null;
    return (_jsx("div", { className: "labels-chart-actions", children: !isSelectChartVisible ? (_jsxs(_Fragment, { children: [wosTrigger ? _jsx(WakeOnShakeIndicator, { chartIdentifier: chartIdentifier }) : _jsx(_Fragment, {}), readingId ? _jsx(FFTIdIndicator, { chartIdentifier: chartIdentifier, readingId: readingId }) : _jsx(_Fragment, {})] })) : (_jsx(_Fragment, {})) }));
};
export default memo(LabelsChartActions);
