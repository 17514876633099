import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { READING_TYPES, READING_TYPE_TEMPERATURE } from '../constants';
export var AlertLogContent = function (_a) {
    var _b = _a.cautionValue, cautionValue = _b === void 0 ? null : _b, _c = _a.warningValue, warningValue = _c === void 0 ? null : _c, _d = _a.isNewData, isNewData = _d === void 0 ? false : _d, _e = _a.readingTypeId, readingTypeId = _e === void 0 ? null : _e, _f = _a.showAxis, showAxis = _f === void 0 ? false : _f;
    return (_jsxs(React.Fragment, { children: [_jsxs(Row, { children: [_jsxs(Col, { className: "text-center", md: 6, children: [showAxis && (_jsxs("span", { children: [' ', readingTypeId ? READING_TYPES[readingTypeId].axis : '', ' '] })), _jsx(Badge, { className: showAxis && readingTypeId && +readingTypeId === READING_TYPE_TEMPERATURE ? 'ms-45' : '', pill: true, color: cautionValue || isNewData ? 'warning' : 'secondary', children: "Caution" })] }), _jsxs(Col, { className: "text-center", md: 6, children: [showAxis && (_jsxs("span", { children: [' ', readingTypeId ? READING_TYPES[readingTypeId].axis : '', ' '] })), _jsx(Badge, { className: showAxis && readingTypeId && +readingTypeId === READING_TYPE_TEMPERATURE ? 'ms-45' : '', pill: true, color: warningValue || isNewData ? 'danger' : 'secondary', children: "Warning" })] })] }), _jsxs(Row, { children: [_jsx(Col, { className: "text-center", md: 6, children: _jsx("span", { className: showAxis ? 'ms-45' : '', children: cautionValue ? parseFloat(String(cautionValue)).toFixed(2) : 'Not set' }) }), _jsx(Col, { className: "text-center", md: 6, children: _jsx("span", { className: showAxis ? 'ms-45' : '', children: warningValue ? parseFloat(String(warningValue)).toFixed(2) : 'Not set' }) })] })] }));
};
export var DefaultAlertLogContent = function (_a) {
    var cautionValue = _a.cautionValue, warningValue = _a.warningValue, _b = _a.showBadges, showBadges = _b === void 0 ? true : _b, _c = _a.readingTypeId, readingTypeId = _c === void 0 ? null : _c;
    return (_jsxs(React.Fragment, { children: [showBadges && (_jsxs(Row, { children: [_jsx(Col, { md: 3 }), _jsx(Col, { md: 4, children: _jsx(Badge, { pill: true, color: 'warning', children: "Caution" }) }), _jsx(Col, { md: 4, children: _jsx(Badge, { pill: true, color: 'danger', children: "Warning" }) })] })), _jsxs(Row, { children: [_jsx(Col, { md: 3, children: _jsxs("span", { children: [' ', readingTypeId ? READING_TYPES[readingTypeId].axis : '', ' '] }) }), _jsx(Col, { md: 4, children: _jsx("span", { children: cautionValue ? parseFloat(String(cautionValue)).toFixed(2) : 'Not set' }) }), _jsx(Col, { md: 4, children: _jsx("span", { children: warningValue ? parseFloat(String(warningValue)).toFixed(2) : 'Not set' }) })] })] }));
};
export default AlertLogContent;
