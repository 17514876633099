var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { groupBy as _groupBy } from 'lodash';
import React, { Fragment, memo, useContext, useEffect, useReducer, useState, } from 'react';
import { Col, Row } from 'reactstrap';
import Endpoints from '../../../../../api/endpoints/endpoints';
import Loader from '../../../../components/Loader';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { reducer } from '../../../../helper/reducer';
import { InstallationPointStore } from '../../../../store/charts/chartsContent/InstallationPointStore';
import '../../MachineInfo.scss';
import Filters from './components/Filters';
import PointActionItems from './components/PointActionItems';
import { fetch, getAisForPoint, hasAisOnComponent } from './features';
import CompletionReportModal from './modals/CompletionReportModal';
var initialState = {
    loader: true,
    activeActionItems: [],
    previousActionItems: [],
    actionListNoActionsTypes: {},
    actionListNoActionsGroups: {},
    actionListReportsSeverity: [],
};
var ActionItemsTab = function () {
    var _a;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var _b = useState({
        report: null,
        isVisibleCompletionReportModal: false,
    }), completionReportModalState = _b[0], setCompletionReportModalState = _b[1];
    var closeCompletionReportModal = function () {
        setCompletionReportModalState(__assign(__assign({}, completionReportModalState), { isVisibleCompletionReportModal: false }));
    };
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    var actionListNoActionsTypes = state.actionListNoActionsTypes, actionListNoActionsGroups = state.actionListNoActionsGroups, actionListReportsSeverity = state.actionListReportsSeverity, loader = state.loader, activeActionItems = state.activeActionItems, previousActionItems = state.previousActionItems;
    var equipment = installationPoint.equipment;
    var pointsOnEquipment = (_a = equipment === null || equipment === void 0 ? void 0 : equipment.installationPoints) !== null && _a !== void 0 ? _a : [];
    var groupedByComponent = _groupBy(pointsOnEquipment, function (point) { return point.component; });
    var components = Object.keys(groupedByComponent).filter(function (component) {
        var _a;
        return hasAisOnComponent((_a = groupedByComponent[component]) !== null && _a !== void 0 ? _a : [], activeActionItems, previousActionItems);
    });
    var _d = useState('collapse'), collapseExpandState = _d[0], setCollapseExpandState = _d[1];
    useEffect(function () {
        fetch(dispatch, function () { return Endpoints[selectedSystemType].getActionItemsHistory(installationPoint.id); });
    }, []);
    return (_jsx(_Fragment, { children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center h-100", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsxs("div", { className: "mt-3", children: [components.length ? (_jsxs(_Fragment, { children: [_jsx(Filters, { setCollapseExpandState: setCollapseExpandState }), components.map(function (component, index) {
                            var _a;
                            return (_jsxs(Fragment, { children: [_jsx(Row, { children: _jsx(Col, { className: "h5", children: component }) }), ((_a = groupedByComponent[component]) !== null && _a !== void 0 ? _a : []).map(function (installationPoint) {
                                        var activeAisOnPoint = getAisForPoint(activeActionItems, installationPoint.id);
                                        var previousAisOnPoint = getAisForPoint(previousActionItems, installationPoint.id);
                                        if (!activeAisOnPoint.length && !previousAisOnPoint.length) {
                                            return _jsx(_Fragment, {});
                                        }
                                        return (_jsx(PointActionItems, { activeActionItems: activeAisOnPoint, previousActionItems: previousAisOnPoint, installationPoint: installationPoint, collapseExpandState: collapseExpandState, setCompletionReportModalState: setCompletionReportModalState }, installationPoint.id));
                                    })] }, index));
                        })] })) : (_jsx("div", { className: "text-center", children: _jsx("b", { children: "There are no data" }) })), completionReportModalState.report ? (_jsx(CompletionReportModal, { visible: completionReportModalState.isVisibleCompletionReportModal, closeModal: closeCompletionReportModal, report: completionReportModalState.report, actionListNoActionsGroups: actionListNoActionsGroups, actionListNoActionsTypes: actionListNoActionsTypes, actionListReportsSeverity: actionListReportsSeverity })) : (_jsx(_Fragment, {}))] })) }));
};
export default memo(ActionItemsTab);
