import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import { FaTimes } from 'react-icons/fa';
import { Button, Col, Row } from 'reactstrap';
import { DateInput } from '../../../../widgets/common';
var Filters = function (_a) {
    var onClearDeclined = _a.onClearDeclined, onClearCreated = _a.onClearCreated, onChangeCreatedBy = _a.onChangeCreatedBy, onChangeDeclinedBy = _a.onChangeDeclinedBy, users = _a.users, exportToCsv = _a.exportToCsv, selected = _a.selected, _b = _a.passUserIdOnChange, passUserIdOnChange = _b === void 0 ? false : _b, onChangeDateRange = _a.onChangeDateRange, dateRange = _a.dateRange;
    return (_jsxs(Row, { children: [_jsx(Col, { md: 7, children: _jsxs("div", { className: "d-flex mb-2", children: [_jsxs("div", { className: "response-select d-inline-block", children: [_jsx("label", { htmlFor: "user-created", children: "Date:" }), _jsx(DatePicker, { selectsRange: true, onChange: onChangeDateRange, dateFormat: "yyyy/MM/dd", isClearable: true, startDate: dateRange[0], endDate: dateRange[1], maxDate: Date.now(), customInput: _jsx(DateInput, {}) })] }), _jsxs("div", { className: "me-2 response-rejected", children: [_jsx("label", { htmlFor: "user-created", children: "Created by:" }), _jsxs("div", { className: "d-flex align-items-center", children: [_jsxs("select", { id: "user-created", value: selected.created_by, className: "form-control me-2 js-user-created-select", onChange: function (ev) { return onChangeCreatedBy(ev.target.value); }, name: "user-created", children: [_jsx("option", { value: "", children: "All users" }), Object.keys(_get(users, 'created', {})).map(function (userId) {
                                                    var userName = users.created[userId];
                                                    userName = userName ? userName.replaceAll(' ', '').replaceAll('-', '') : '';
                                                    return userName ? (_jsx("option", { value: passUserIdOnChange ? userId : users.created[userId], children: users.created[userId] }, "user_created_filter".concat(users.created[userId]))) : ('');
                                                })] }), _jsx("div", { className: "d-inline-block", children: _jsx(Button, { size: "sm", className: "filter-reset-button", color: "danger", onClick: onClearCreated, children: _jsx(FaTimes, {}) }) })] })] }), _jsxs("div", { className: "response-rejected", children: [_jsx("label", { htmlFor: "user-declined", children: "Declined by:" }), _jsxs("div", { className: "d-flex align-items-center", children: [_jsxs("select", { id: "user-declined", value: selected.declined_by, className: "form-control me-2 js-user-declined-select", onChange: function (ev) { return onChangeDeclinedBy(ev.target.value); }, name: "user-declined", children: [_jsx("option", { value: "", children: "All users" }), Object.keys(_get(users, 'declined', {})).map(function (userId) {
                                                    var userName = users.declined[userId];
                                                    userName = userName ? userName.replaceAll(' ', '').replaceAll('-', '') : '';
                                                    return userName ? (_jsx("option", { value: passUserIdOnChange ? userId : users.declined[userId], children: users.declined[userId] }, "user_declined_filter".concat(users.declined[userId]))) : ('');
                                                })] }), _jsx("div", { className: "d-inline-block", children: _jsx(Button, { size: "sm", className: "filter-reset-button", color: "danger", onClick: onClearDeclined, children: _jsx(FaTimes, {}) }) })] })] })] }) }), _jsx(Col, { md: 5, className: "custom-position", children: _jsx(Button, { onClick: exportToCsv, color: "success", className: "float-end response-rejected-button custom-position-button", size: "sm", children: "Export to csv" }) })] }));
};
export default memo(Filters);
