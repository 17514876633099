var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, indexOf as _indexOf, isArray as _isArray, size as _size } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import Endpoints from '../../../../../../../api/endpoints/endpoints';
import { DEFAULT_CHART_LIST, METRIC_KEY } from '../../../../../../../constants';
import { getTimezone } from '../../../../../../features/shared';
export var FftTwfCalendar = function (_a) {
    var chartIdentifier = _a.chartIdentifier, _b = _a.fftTimestamps, fftTimestamps = _b === void 0 ? {} : _b, width = _a.width, isDisabled = _a.isDisabled, fftTimestampDates = _a.fftTimestampDates, fftSelectedDate = _a.fftSelectedDate, setStatesFftChartsStore = _a.setStatesFftChartsStore;
    var currentDate = useMemo(function () {
        return _get(fftTimestampDates, chartIdentifier);
    }, [fftTimestampDates]);
    var _c = useState(fftTimestamps[moment(currentDate).format('YYYY-MM-DD')] || []), activeFftDates = _c[0], setActiveFftDates = _c[1];
    var _d = useState(moment(currentDate).format('YYYY-MM-DD HH:mm:ss')), selectedDate = _d[0], setSelectedDate = _d[1];
    var allTimestamps = [];
    (Object.keys(fftTimestamps) || []).map(function (el) {
        (fftTimestamps[el] || []).map(function (elem) {
            if (elem.timestamp) {
                allTimestamps.push(elem.timestamp);
            }
        });
    });
    var index = _indexOf(allTimestamps, selectedDate);
    var nextDate = '', prevDate = '';
    if (+index !== -1) {
        nextDate = allTimestamps[index + 1] ? allTimestamps[index + 1].trim() : '';
        prevDate = allTimestamps[index - 1] ? allTimestamps[index - 1].trim() : '';
    }
    var onCalendarChangeDate = function (date, isPrevOrNext) {
        if (isPrevOrNext === void 0) { isPrevOrNext = false; }
        var key = moment(date).format('YYYY-MM-DD');
        if (!fftTimestamps[key]) {
            return;
        }
        var newDateTime = fftTimestamps[key].filter(function (el) {
            return el.timestamp.includes(moment(date).format('YYYY-MM-DD HH:mm'));
        });
        setActiveFftDates(fftTimestamps[key]);
        setSelectedDate(!newDateTime.length ? fftTimestamps[key][0].timestamp : newDateTime[0].timestamp);
        if (isPrevOrNext) {
            onCalendarChange(!newDateTime.length ? fftTimestamps[key][0].timestamp : newDateTime[0].timestamp);
        }
    };
    var onCalendarChange = function (date) {
        setStatesFftChartsStore({
            fftSelectedDate: date,
            fftSelectedDateChartIdentifier: chartIdentifier,
        });
    };
    useEffect(function () {
        if (fftSelectedDate && selectedDate !== fftSelectedDate) {
            onCalendarChangeDate(fftSelectedDate);
        }
    }, [fftSelectedDate]);
    useEffect(function () {
        onCalendarChangeDate(currentDate);
    }, [fftTimestampDates]);
    if (!currentDate) {
        return _jsx(React.Fragment, {});
    }
    return (_jsx("div", { className: "chart-btn", children: _jsxs("div", { className: "fft-calendar d-flex chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), style: { width: width }, children: [_jsx("button", { className: "btn btn-mini", disabled: !prevDate || isDisabled, onClick: function () { return onCalendarChangeDate(new Date(prevDate), true); }, children: "\u25C4" }), _jsx(DatePicker, { readOnly: isDisabled, dateFormat: "yyyy-MM-dd h:mm aa", onChange: function (date) { return onCalendarChangeDate(date); }, onCalendarClose: function () { return onCalendarChange(selectedDate); }, value: selectedDate, showTimeSelect: true, timeFormat: "HH:mm:ss", injectTimes: activeFftDates.map(function (el) { return moment(el.timestamp).unix() * 1000; }), includeTimes: activeFftDates.map(function (el) { return moment(el.timestamp).unix() * 1000; }), selected: new Date(selectedDate), filterDate: function (date) { return _size(_get(fftTimestamps, moment(date).format('YYYY-MM-DD'))); }, maxDate: new Date(), showDisabledMonthNavigation: true, customInput: _jsx(CustomInput, {}) }), _jsx("button", { className: "btn btn-mini", disabled: !nextDate || isDisabled, onClick: function () { return onCalendarChangeDate(new Date(nextDate), true); }, children: "\u25BA" })] }) }));
};
export var CustomInput = React.forwardRef(function CustomInput(props, ref) {
    var originDate = _get(props, 'value');
    var date = moment(originDate).format('MM-DD-YYYY HH:mm:ss');
    return (_jsx("button", { className: "btn btn-mini date-handler-btn date-btn", onClick: props.onClick, ref: ref, children: date }));
});
export var fetchFftData = function (readingFftIds, installationPoint, setStatesFftChartsStore, data, readings, selectedSystemType, measure, isImpactVueChart, interpolationDisabled) {
    if (isImpactVueChart === void 0) { isImpactVueChart = false; }
    if (interpolationDisabled === void 0) { interpolationDisabled = false; }
    setStatesFftChartsStore({ loader: true });
    var timezone = getTimezone(installationPoint, selectedSystemType);
    Endpoints[selectedSystemType]
        .getAllFftByIds(installationPoint.id, {
        duplicate: true,
        query: {
            isHFDvue: +isImpactVueChart,
            readingFftIds: _isArray(readingFftIds) ? readingFftIds : [readingFftIds],
            highpass: _get(installationPoint, 'settings.high_pass', ''),
            interpolationDisabled: +interpolationDisabled,
            selected_facility_metric: METRIC_KEY[measure],
            timezone: timezone,
        },
    })
        .then(function (resp) {
        if (resp && Object.values(resp).length > 0) {
            var new_readings_1 = {};
            var new_autoCorrelation_1 = {};
            var new_pkpk_1 = {};
            var new_overall_1 = {};
            Object.keys(resp).map(function (readingTypeId) {
                var _a, _b, _c, _d;
                var readingTypeIdOrigin = isImpactVueChart ? +readingTypeId + 100 : +readingTypeId;
                if (DEFAULT_CHART_LIST.includes(readingTypeIdOrigin)) {
                    Object.assign(new_readings_1, __assign(__assign({}, new_readings_1), (_a = {}, _a[readingTypeIdOrigin] = _get(resp, readingTypeId + '.data'), _a)));
                    Object.assign(new_autoCorrelation_1, __assign(__assign({}, new_autoCorrelation_1), (_b = {}, _b[readingTypeIdOrigin] = _get(resp, readingTypeId + '.autoCorrelation'), _b)));
                    Object.assign(new_pkpk_1, __assign(__assign({}, new_pkpk_1), (_c = {}, _c[readingTypeIdOrigin] = _get(resp, readingTypeId + '.pkpk'), _c)));
                    Object.assign(new_overall_1, __assign(__assign({}, new_overall_1), (_d = {}, _d[readingTypeIdOrigin] = _get(resp, readingTypeId + '.overall'), _d)));
                }
            });
            setStatesFftChartsStore({
                loader: false,
                isChangedCalendar: false,
                readings: __assign(__assign({}, readings), new_readings_1),
                data: __assign(__assign({}, data), { auto_correlation: __assign(__assign({}, data.auto_correlation), new_autoCorrelation_1), pkpk: __assign(__assign({}, data.pkpk), new_pkpk_1), overall: __assign(__assign({}, data.overall), new_overall_1) }),
            });
            return;
        }
        setStatesFftChartsStore({
            loader: false,
            isChangedCalendar: false,
            readings: readings,
            data: data,
        });
    });
};
