var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { toast } from 'react-toastify';
import AuthService from '../shared/features/shared/auth';
export var activeRequests = [];
export var setActiveRequests = function (requests) {
    activeRequests = requests;
};
export default {
    getUrl: function (options) {
        var opt = options || {};
        var host = opt.host || APP_API_URL;
        var query = opt.query || {};
        var params = $.param(query);
        // @ts-ignore
        return Object.assign({}, this.options, opt, {
            toString: function () {
                // @ts-ignore
                return "".concat(host, "/").concat(this.url).concat(params.length > 0 ? '?' + params : '');
            },
        });
    },
    getHeaders: function (isEmptyContentType) {
        if (isEmptyContentType === void 0) { isEmptyContentType = false; }
        var auth = new AuthService();
        var headers = isEmptyContentType ? {} : { 'Content-Type': 'application/json' };
        if (auth.loggedIn()) {
            Object.assign(headers, { Authorization: "Bearer ".concat(auth.getToken()) });
        }
        return headers;
    },
    getApiFileHeaders: function () {
        var auth = new AuthService();
        return { Authorization: "Bearer ".concat(auth.getToken()) };
    },
    get: function (url, options) {
        if (options === void 0) { options = {}; }
        return this.fetch(this.getUrl(__assign({ url: url }, options)), Object.assign({}, {
            method: 'GET',
            headers: this.getHeaders(),
            credentials: 'same-origin',
        }, options));
    },
    post: function (url, body, options) {
        if (options === void 0) { options = {}; }
        return this.fetch(this.getUrl(__assign({ url: url }, options)), Object.assign({}, {
            method: 'POST',
            headers: this.getHeaders(),
            credentials: 'same-origin',
        }, options, { body: JSON.stringify(body) }));
    },
    file: function (url, body, options) {
        if (options === void 0) { options = {}; }
        return this.fetch(this.getUrl(__assign({ url: url }, options)), {
            // @ts-ignore
            method: 'POST',
            headers: this.getApiFileHeaders(),
            body: body,
            credentials: 'same-origin',
        });
    },
    del: function (url, body, options) {
        if (options === void 0) { options = {}; }
        return this.fetch(this.getUrl(__assign({ url: url }, options)), Object.assign({}, {
            method: 'DELETE',
            headers: this.getHeaders(),
            credentials: 'same-origin',
        }, options, { body: JSON.stringify(body) }));
    },
    put: function (url, body, options) {
        if (options === void 0) { options = {}; }
        return this.fetch(this.getUrl(__assign({ url: url }, options)), Object.assign({}, {
            method: 'PUT',
            headers: this.getHeaders(),
            credentials: 'same-origin',
        }, options, { body: JSON.stringify(body) }));
    },
    fetch: function (endpoint, options) {
        if (options === void 0) { options = {}; }
        var url = endpoint.url;
        /* flag duplicated in query for use request in different component
                query: {
                    duplicated: true
                }
                then we will check it in activeRequests
            */
        activeRequests.map(function (item) {
            if (item.url === url && (!endpoint.query || !endpoint.query.duplicated)) {
                item.controller.abort();
                activeRequests = activeRequests.filter(function (item) { return item.url !== url; });
                return true;
            }
        });
        var controller = new window.AbortController();
        activeRequests.push({ url: url, controller: controller });
        options.signal = controller.signal;
        // @ts-ignore
        return fetch(endpoint, options)
            .then(function (resp) {
            activeRequests = activeRequests.filter(function (item) { return item.url !== url; });
            if (resp.ok) {
                return options.isBlob ? resp.blob() : resp.json();
            }
            if (resp.status === 401 && !options.keepalive) {
                window.location.href = "".concat(APP_SSO_URL, "/login?redirect=").concat(window.location.href);
            }
            return resp.json().then(function (err) {
                err.status = resp.status;
                throw err;
            });
        })
            .catch(function (err) {
            if (err.name === 'AbortError') {
                return;
            }
            activeRequests = activeRequests.filter(function (item) { return item.url !== url; });
            if (options.errorSkip) {
                return {};
            }
            else if (options.isBlob) {
                return {};
            }
            else if (err.status === 404) {
                toast.error(_jsxs(_Fragment, { children: [_jsx("b", { children: "404" }), _jsx("br", {}), err.message || 'Endpoint not found'] }));
            }
            else if (err.status === 422) {
                return;
            }
            else {
                if (err.name !== 'Unauthorized') {
                    toast.error(_jsxs(_Fragment, { children: [_jsx("b", { children: err.name || 'Error' }), _jsx("br", {}), err.message || 'Something went wrong'] }));
                }
            }
            throw err;
        });
    },
};
