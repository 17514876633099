import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React from 'react';
import { Button } from 'reactstrap';
import { ACTION_LIST } from '../../../../constants';
import { DateTimeLabel } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import Filters from '../../../components/mlStatistic/rejected/Filter';
import { highlightRow } from '../../../helper/table';
export var config = function (onViewClick) {
    return {
        columns: [
            {
                name: 'point_name',
                title: 'Point name',
                sortable: true,
            },
            {
                name: 'sensor_hex',
                title: 'Serial/Sensor',
                sortable: true,
                component: function (row) {
                    return (_jsxs("div", { className: "no-wrap", children: [row.serial || '', row.serial && '/', _jsx(SensorId, { sensor_id: +row.sensor_id, showDecId: false })] }));
                },
            },
            {
                name: 'time_created',
                title: 'Date created',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "ai_list_time_created_".concat(row.id), dateTime: row.time_created, timeBreak: true }));
                },
            },
            {
                name: 'created_by',
                title: 'Created by',
                sortable: true,
            },
            {
                name: 'submited_by',
                title: 'Closed by',
                sortable: true,
            },
            {
                name: 'updated_at',
                title: 'Last Update',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "ai_list_updated_at_".concat(row.id), dateTime: row.updated_at, timeBreak: true }));
                },
            },
            {
                name: 'no_actions_description',
                title: 'Close comment',
                sortable: false,
                component: function (row) {
                    var isActiveAi = [ACTION_LIST.STATE_CLOSED, ACTION_LIST.STATE_ARCHIVE].indexOf(+row.state) === -1;
                    var comment = _get(row, 'reports.0.ww_comment') ||
                        _get(row, 'wwReports.0.ww_comment') ||
                        row.no_actions_description;
                    return !isActiveAi && comment ? (_jsx("span", { className: "has-image", dangerouslySetInnerHTML: { __html: comment.replaceAll(/(style=".+?")/gm, '') } })) : ('---');
                },
            },
            {
                title: 'Analyst comment',
                name: 'ml_comment',
                sortable: false,
                component: function (row) {
                    return (_jsx("div", { className: "w-280 table-cell-with-img", children: row.mlComments.map(function (mlComment) { return (_jsxs("div", { children: [_jsxs("b", { children: [mlComment.username, ":"] }), _jsx("span", { className: "default-style-text", dangerouslySetInnerHTML: {
                                        __html: mlComment.ml_comment.replaceAll(/(style=".+?")/gm, ''),
                                    } })] }, mlComment.id)); }) }));
                },
            },
            {
                name: 'actions',
                sortable: false,
                component: function (row) {
                    return (_jsx(Button, { color: "primary", className: "me-2 position-relative", size: "sm", onClick: function (ev) {
                            onViewClick(row.id);
                            highlightRow(ev);
                        }, children: "View" }));
                },
            },
        ],
        sort: [
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ],
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        search: '',
        data: [],
        loader: true,
        filters: function (callbacks, filtersData, selectedFilters) {
            return (_jsx(Filters, { onChangeDateRange: callbacks.onChangeDateRange, dateRange: filtersData.dateRange, onClearCreated: callbacks.onClearCreated, onClearDeclined: callbacks.onClearDeclined, onChangeCreatedBy: callbacks.onChangeCreatedBy, onChangeDeclinedBy: callbacks.onChangeDeclinedBy, users: filtersData.users, exportToCsv: callbacks.exportToCsv, selected: selectedFilters }));
        },
        filtersData: {
            dateRange: [],
            users: [],
            selectedCustomers: [],
        },
    };
};
