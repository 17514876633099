import Endpoints from '../../../api/endpoints/endpoints';
import Cookie from '../../helper/cookie';
var AuthService = /** @class */ (function () {
    function AuthService() {
        var _this = this;
        this.loggedIn = function () { return !!_this.getToken(); };
        this.getToken = function () { return Cookie.get(APP_COOKIE_NAME || ''); };
        this.logout = function () {
            window.location.href = APP_SSO_URL + '/logout-simple/' + APP_SSO_CLIENT_ID;
        };
        this.getProfile = this.getProfile.bind(this);
    }
    AuthService.prototype.getProfile = function () {
        return Endpoints.sso.profile();
    };
    return AuthService;
}());
export default AuthService;
