import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Input, Label, Row } from 'reactstrap';
var HighValueFilterRow = function (_a) {
    var type = _a.type, measure = _a.measure, defaultState = _a.defaultState, onChangeHighValues = _a.onChangeHighValues;
    var _b = useState(defaultState.isEnabled), isEnabled = _b[0], setIsEnabled = _b[1];
    var _c = useState(defaultState.fromValue), fromValue = _c[0], setFromValue = _c[1];
    var _d = useState(defaultState.toValue), toValue = _d[0], setToValue = _d[1];
    var onClear = function () {
        setFromValue('');
        setToValue('');
    };
    var onResetToDefault = function () {
        setFromValue(defaultState.fromValue);
        setToValue(defaultState.toValue);
    };
    useEffect(function () {
        onChangeHighValues(type, isEnabled, fromValue, toValue);
    }, [isEnabled, fromValue, toValue]);
    return (_jsxs(Row, { className: "mb-1", children: [_jsx(Col, { md: 4, className: "d-flex align-items-center", children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: isEnabled, onChange: function () { return setIsEnabled(function (state) { return !state; }); } }), _jsxs("span", { children: [type.toUpperCase(), " (", measure, ")"] })] }) }), _jsx(Col, { md: 2, children: _jsx(Input, { type: "number", value: fromValue, min: 0, onChange: function (ev) { return setFromValue(+ev.target.value); } }) }), _jsx(Col, { md: 2, children: _jsx(Input, { type: "number", value: toValue, min: 0, onChange: function (ev) { return setToValue(+ev.target.value); } }) }), _jsx(Col, { md: 2, children: _jsxs(ButtonGroup, { children: [_jsx(Button, { onClick: function () { return onClear(); }, color: "primary", className: "button-ml position-relative", children: "Clear" }), _jsx(Button, { onClick: function () { return onResetToDefault(); }, color: "secondary", className: "button-ml position-relative", children: "To default" })] }) })] }));
};
export default memo(HighValueFilterRow);
