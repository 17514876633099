import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { size as _size } from 'lodash';
import React from 'react';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { UncontrolledTooltip } from 'reactstrap';
export var ExistingAlertCondition = function (_a) {
    var alertCondition = _a.alertCondition, suggestedLevel = _a.suggestedLevel, precision = _a.precision;
    if (!_size(alertCondition)) {
        return _jsx(_Fragment, { children: "Not set" });
    }
    var getIcon = function (analyticValue, systemValue) {
        if (analyticValue > systemValue) {
            var randomNumber = Math.trunc(Math.random());
            return (_jsxs(_Fragment, { children: [_jsx(FaLongArrowAltUp, { id: "FaLongArrowAltUp_".concat(randomNumber, "_").concat(alertCondition.alert_level_id, "_").concat(alertCondition.reading_type_id), className: "text-success cursor-pointer" }), _jsx(UncontrolledTooltip, { target: "FaLongArrowAltUp_".concat(randomNumber, "_").concat(alertCondition.alert_level_id, "_").concat(alertCondition.reading_type_id), children: "Analytic alert value is greater than system" })] }));
        }
        if (analyticValue < systemValue) {
            var randomNumber = Math.trunc(Math.random());
            return (_jsxs(_Fragment, { children: [_jsx(FaLongArrowAltDown, { id: "FaLongArrowAltDown_".concat(randomNumber, "_").concat(alertCondition.alert_level_id, "_").concat(alertCondition.reading_type_id), className: "text-danger cursor-pointer" }), _jsx(UncontrolledTooltip, { target: "FaLongArrowAltDown_".concat(randomNumber, "_").concat(alertCondition.alert_level_id, "_").concat(alertCondition.reading_type_id), children: "Analytic alert value is less than system" })] }));
        }
        return _jsx(_Fragment, {});
    };
    return (_jsxs("span", { children: [parseFloat(alertCondition.value).toFixed(precision), suggestedLevel ? getIcon(parseFloat(alertCondition.value), parseFloat(suggestedLevel.value)) : ''] }));
};
