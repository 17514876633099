import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { copyTextToClipboard } from '../../../../../../helper/dom';
import DefaultTooltip from '../../../../../DefaultTooltip';
export var FFTIdIndicator = function (_a) {
    var chartIdentifier = _a.chartIdentifier, readingId = _a.readingId;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "default-chart-label", "data-tooltip-id": "fft-id-".concat(chartIdentifier), children: _jsx("span", { onClick: function () { return copyTextToClipboard(String(readingId)); }, className: "me-1 cursor-pointer", children: "FFT Id" }) }), _jsx(DefaultTooltip, { place: "left", id: "fft-id-".concat(chartIdentifier), style: {
                    maxWidth: '300px',
                    textAlign: 'center',
                }, children: readingId })] }));
};
FFTIdIndicator.displayName = 'FFTIdIndicator';
