import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { selectMeasure } from '../../../store/global/useGlobalStore';
import BackToList from '../../AIPreAi/BackToList/BackToList';
import Title from '../Title/Title';
var Header = function (_a) {
    var installationPointForActionItemData = _a.installationPointForActionItemData;
    var installationPointName = installationPointForActionItemData.installationPointName, sensorId = installationPointForActionItemData.sensorId, installationPoint = installationPointForActionItemData.installationPoint, equipmentType = installationPointForActionItemData.equipmentType, installationPointId = installationPointForActionItemData.installationPointId, facilityMeasure = installationPointForActionItemData.facilityMeasure;
    var measure = selectMeasure();
    useEffect(function () {
        if (facilityMeasure !== measure) {
            toast.warning('Your measurement settings are different from the facility');
        }
    }, [measure]);
    return (_jsx("div", { className: "page-header", children: _jsx("div", { className: "d-flex justify-content-between", children: _jsxs("div", { className: "d-flex", children: [_jsx(BackToList, { to: "/action-list" }), _jsx(Title, { installationPointName: installationPointName, sensorId: sensorId, isSensor: true, installationPoint: installationPoint, equipmentType: equipmentType, installationPointId: installationPointId, isShowPrefix: true })] }) }) }));
};
export default memo(Header);
