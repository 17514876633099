import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { memo, useContext, useReducer, useRef } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { Button, ButtonGroup, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { READING_TYPES } from '../../constants';
import { SystemTypeContext } from '../../shared/context/SystemTypeContext';
import { getTimezone } from '../../shared/features/shared';
import { reducer } from '../../shared/helper/reducer';
import { ChartFeaturesStore } from '../../shared/store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../shared/store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure, } from '../../shared/store/global/useGlobalStore';
import './HidePeaks.scss';
import { getHidePeaksChartOptions, onCancel, onChangePeak, onSave } from './features';
import { usePeakUpdatePlotLinesZone } from './hooks/usePeakUpdatePlotLinesZone';
var initialState = function (state, chartIdentifier, measure, personalSettingMeasure, timezone) {
    var _a, _b;
    return {
        peak: (_a = state.hidePeaksOptions.peak) !== null && _a !== void 0 ? _a : null,
        options: getHidePeaksChartOptions(state.options, chartIdentifier, (_b = state.hidePeaksOptions.peak) !== null && _b !== void 0 ? _b : null, measure, personalSettingMeasure, timezone),
    };
};
var HidePeaksModal = function (_a) {
    var visible = _a.visible, state = _a.state, dispatch = _a.dispatch, chartIdentifier = _a.chartIdentifier;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var mainSeriesLoaded = state.mainSeriesLoaded, hidePeaksOptions = state.hidePeaksOptions;
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var timezone = getTimezone(installationPoint, selectedSystemType);
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    if (!visible || !mainSeriesLoaded) {
        return _jsx(_Fragment, {});
    }
    var chartRef = useRef(null);
    var _b = useReducer(reducer, initialState(state, chartIdentifier, measure, personalSettingMeasure, timezone)), hidePeaksState = _b[0], hidePeaksDispatch = _b[1];
    var peak = hidePeaksState.peak, options = hidePeaksState.options;
    var setStates = ChartFeaturesStore(function (state) { return ({
        setStates: state.setStates,
    }); }, shallow).setStates;
    usePeakUpdatePlotLinesZone([peak], hidePeaksState, hidePeaksDispatch);
    return (_jsxs(Modal, { className: "hidepeaks-modal modal-response", toggle: function () { return onCancel(dispatch, hidePeaksDispatch, hidePeaksOptions, setStates); }, isOpen: visible, centered: true, fade: false, size: "lg", children: [_jsxs(ModalHeader, { toggle: function () { return onCancel(dispatch, hidePeaksDispatch, hidePeaksOptions, setStates); }, children: ["Hide Peaks - ", READING_TYPES[chartIdentifier].title, ' ', READING_TYPES[chartIdentifier].axis] }), _jsxs(ModalBody, { children: [_jsx("div", { className: "editing-area", children: _jsxs(FormGroup, { className: "edit-peak-block", children: [_jsxs(Label, { for: "peak", children: ["Peak", _jsx(FaRegQuestionCircle, { id: "peakQuestion", className: "ms-2" }), _jsx(UncontrolledTooltip, { target: "peakQuestion", children: "Peak is exceptional" })] }), _jsx("div", { className: "d-flex", children: _jsx(Input, { id: "peak", type: "number", value: peak !== null && peak !== void 0 ? peak : '', placeholder: "Hide all points above...", onChange: function (ev) { return onChangePeak(ev.target.value, hidePeaksDispatch); } }) })] }) }), _jsx(HighchartsReact, { ref: chartRef, highcharts: Highcharts, options: options })] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: function () { return onCancel(dispatch, hidePeaksDispatch, hidePeaksOptions, setStates); }, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: function () { return onSave(dispatch, peak, setStates); }, children: "Save" })] }) })] }));
};
export default memo(HidePeaksModal);
