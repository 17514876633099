import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Input, InputGroup } from 'reactstrap';
var FilterSensorSearch = function (_a) {
    var state = _a.state, dispatch = _a.dispatch;
    var sensorIdsInput = state.sensorIdsInput;
    if (sensorIdsInput === void 0) {
        return _jsx(_Fragment, {});
    }
    var onChange = function (dispatch, field, value) {
        var _a;
        dispatch({
            type: 'setState',
            state: (_a = {},
                _a[field] = value,
                _a),
        });
    };
    return (_jsxs(InputGroup, { className: "float-start w-40", children: [_jsx(Input, { size: "sm", placeholder: "Sensor Hex (for several sensors write id separated by commas)", onChange: function (ev) {
                    return onChange(dispatch, 'sensorIdsInput', ev.target.value);
                }, onKeyDown: function (ev) {
                    if (ev.key === 'Enter') {
                        onChange(dispatch, 'sensorIds', sensorIdsInput);
                    }
                }, className: "sensor-log-input", value: sensorIdsInput }), _jsx("div", { className: "input-group-append ms-2", children: _jsx(Button, { color: "primary", size: "sm", onClick: function () { return onChange(dispatch, 'sensorIds', sensorIdsInput); }, children: "Search" }) })] }));
};
export default FilterSensorSearch;
