import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Badge } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ReactComponent as Radiator } from '../../../../../../../assets/svg/radiator.svg';
import { useHeatsinkUpdateMutation, } from '../../../../../../../remastered/services/heatsink/useHeatsinkUpdateMutation';
import { InstallationPointStore } from '../../../../../../store/charts/chartsContent/InstallationPointStore';
import { LeftEquipmentListStore } from '../../../../../../store/charts/leftEquipmentList/LeftEquipmentListStore';
import { useConfirmModalActions } from '../../../../../../store/global/useConfirmModalStore';
import DefaultTooltip from '../../../../../DefaultTooltip';
import TooltipBlockData from '../components/TooltipBlockData/TooltipBlockData';
var HeatsinkLabel = function () {
    var setHeatsinkEquipment = LeftEquipmentListStore(function (state) { return state.setHeatsink; });
    var _a = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        setHeatsink: state.setHeatsink,
    }); }, shallow), installationPoint = _a.installationPoint, setHeatsink = _a.setHeatsink;
    var _b = useHeatsinkUpdateMutation(), heatsinkUpdateMutate = _b.mutate, heatsinkUpdateIsLoading = _b.isPending;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var handler = function () {
        if (heatsinkUpdateIsLoading) {
            return;
        }
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure you want to unmarked this sensor?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                heatsinkUpdateMutate({
                    isHeatsink: false,
                    installationPointId: installationPoint.id,
                }, {
                    onSuccess: function () {
                        setHeatsink(false);
                        setHeatsinkEquipment(false, installationPoint.equipment_id, installationPoint.id);
                        toast.success('Successfully changed!', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    },
                    onError: function () {
                        toast.error('Something went wrong!', { icon: _jsx(FaTimes, { style: { fill: '#af2929' } }) });
                    },
                });
            },
        });
    };
    if (!(installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.is_heatsink)) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "tag-item", onClick: handler, "data-tooltip-id": "radiator", children: _jsx(TooltipBlockData, { icon: _jsx(Badge, { color: "primary", children: _jsx(Radiator, { style: { width: '22px' } }) }) }) }), _jsx(DefaultTooltip, { id: "radiator", place: "bottom", children: "Sensor is marked as an Insulator" })] }));
};
export default memo(HeatsinkLabel);
